import { useEffect, useState, useRef, createRef } from "react";

const HighchartComponent = ({ chatLog, setErrorSyntax, data, index }) => {

    // This state takes all the highchart code from chatLog and put it in an array
    //same thing for highchartId, each generated highchart has its own Id.

    const [highchartCodes, setHighchartCodes] = useState(chatLog.map(log => log.highchartCode));
    const [highchartIds, setHighchartIds] = useState(chatLog.map(log => log.highchartId));

    // We have put a reference array on modalBtn to detected the appropriate chart to open the editor for.
    const modalButtonRefs = useRef([]);
    const [modalEditors, setModalEditors] = useState([]);

    // this is used to keep the differentiate between modal editorss
    const [clickedIndex, setClickedIndex] = useState(null);

    // These two useEffects are being updated whenever chatLog updates or if there was a new Highchart code/Id.
    useEffect(() => {
        setHighchartCodes(chatLog.map(log => log.highchartCode));
    }, [chatLog]);

    useEffect(() => {
        setHighchartIds(chatLog.map(log => log.highchartId));
    }, [chatLog]);


    // This function aimes to get the json used to display charts from the result.

    const parseHighchartOptions = (script) => {
        const regex = /Highcharts.chart\('.*?',\s*({[\s\S]*})\);/m;
        const match = script.match(regex);
        if (match && match[1]) {
            return eval(`(${match[1]})`);
        } else {
            // Throw an error indicating that chart options couldn't be parsed
            throw new Error("Could not parse chart options from the script string.");
        }
    };

    // This function updates the highchartId with our own generated Id.
    const generateHighchartScript = (chartId, options) => {
        return `Highcharts.chart('${chartId}', ${JSON.stringify(options, null, 2)});`;
    };

    // It changes the Logo fo the editor
    const changeBackgroundImage = (imageUrl) => {
        if (imageUrl) {
            const divElements = document.querySelectorAll('.highed-toolbar-left');
            divElements.forEach(divElement => {
                if (divElement) {
                    const spanElement = divElement.querySelector('span');

                    if (spanElement) {
                        spanElement.style.backgroundImage = `url(${imageUrl})`;
                        spanElement.style.backgroundSize = "60%";
                        spanElement.style.marginTop = "4px";
                    }
                }
            });
        }
    };

    // Display only the editor of the clicked chart
    useEffect(() => {
        if (modalEditors && clickedIndex !== null) {
            modalEditors[clickedIndex].show();
            setClickedIndex(null);
        }
    }, [modalEditors, clickedIndex]);

    // It displays the highchart in the user screen if there was no syntax error.
    useEffect(() => {
        highchartCodes.forEach((code, index) => {
            console.log(code)
            const script = document.createElement('script');
            // Wrap the code in a try-catch block
            try {
                if (code) {
                    new Function(code);
                    script.innerHTML = code;
                    script.async = true;
                    const element = document.getElementById(highchartIds[index]);
                    // Clear the element's contents before appending the new script
                    if (element) {
                        while (element.firstChild) {
                            element.firstChild.remove();
                        }
                        element.appendChild(script);
                    }
                }
            } catch (error) {
                setErrorSyntax(prevErrorSyntax => ({
                    ...prevErrorSyntax,
                    [highchartIds[index]]: true
                }));
            }

        });
    }, [highchartCodes, highchartIds]);


    // Opening the modal editor for each highchart
    // Include the csv to the modal editor
    // Update the highchartCode of the user updates something in the editor
    const openModalEditor = (index) => {
        if (!modalEditors[index]) {
            const chartOptions = parseHighchartOptions(highchartCodes[index]);
            let csvData = chartOptions.data.csv;
            const newModal = window.highed.ModalEditor(modalButtonRefs.current[index], {
                allowDone: true,
                defaultChartOptions: chartOptions,
                features: 'import export templates customize welcome done',
                importer: {
                    options: 'plugins csv json samples',
                },
            }, function () {
                const editedtChart = newModal.editor.getEmbeddableJSON()
                const newChart = generateHighchartScript(highchartIds[index], editedtChart)

                setHighchartCodes(prevCodes => {
                    const newCodes = [...prevCodes];
                    newCodes[index] = newChart;
                    return newCodes;
                });
            });

            newModal.editor.dataTable.clear();
            newModal.editor.dataTable.loadCSV({ csv: csvData });
            changeBackgroundImage("https://askzuro.com/wp-content/uploads/2023/03/zuro_header_logo.svg")
            setModalEditors(modalEditors => {
                const updatedModalEditors = [...modalEditors];
                updatedModalEditors[index] = newModal;
                return updatedModalEditors;
            });
        }
    };
    useEffect(() => {
        chatLog.forEach((data, index) => {
            if (data.highchartCode) {
                modalButtonRefs.current[index] = createRef();
            }
        });
    }, [chatLog]);
    
    return (
        <>
            <div className="flex items-end justify-end mt-4 relative">
                <div className="rounded-lg bg-white">
                    <div id={data} style={{ width: "100%", borderRadius: "10px" }}>
                    </div>
                </div>
                <img className="rounded-[4px] w-[46px] h-[46px] ml-[12px] mr-[8px]"
                    src={`/assets/images/touchpoint.png`}
                    alt="worker" />
                <div ref={(node) => {
                    if (node !== null) {
                        modalButtonRefs.current[index] = node;
                    }
                }} className="absolute top-0 right-[10px] cursor-pointer">
                    <span onClick={() => {
                        setClickedIndex(index);
                        openModalEditor(index);
                    }}>
                        <svg style={{ width: "24px" }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21,12a1,1,0,0,0-1,1v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4h6a1,1,0,0,0,0-2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12ZM6,12.76V17a1,1,0,0,0,1,1h4.24a1,1,0,0,0,.71-.29l6.92-6.93h0L21.71,8a1,1,0,0,0,0-1.42L17.47,2.29a1,1,0,0,0-1.42,0L13.23,5.12h0L6.29,12.05A1,1,0,0,0,6,12.76ZM16.76,4.41l2.83,2.83L18.17,8.66,15.34,5.83ZM8,13.17l5.93-5.93,2.83,2.83L10.83,16H8Z" fill="#6563ff" /></svg>
                    </span>
                </div>
            </div>
        </>
    )
}

export default HighchartComponent