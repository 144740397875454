import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Card,
  CardBody,
} from "@material-tailwind/react";
import Pagination from "../layouts/Pagination";

import { useState } from "react";
import SearchInput from "../layouts/SearchInput";
import { getConversation } from "../../api/queriesService";
import FileSaver from "file-saver";

function Icon({ id, open }) {
  return (
    <svg
      className={`${
        id === open ? "rotate-180" : ""
      } transition-transform ml-0 mr-4`}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9998 9.84685C19.0037 9.68177 18.9523 9.51966 18.8526 9.38306C18.753 9.24646 18.61 9.14215 18.4437 9.08464C18.2774 9.02713 18.096 9.01928 17.9247 9.06217C17.7534 9.10506 17.6008 9.19657 17.4879 9.32397L13.0138 14.185L8.54126 9.32397C8.47167 9.23584 8.38332 9.16204 8.28167 9.10718C8.18001 9.05231 8.06725 9.01758 7.95051 9.00515C7.83376 8.99272 7.71554 9.00285 7.6032 9.03493C7.49086 9.067 7.38681 9.12032 7.29764 9.19154C7.20846 9.26276 7.13612 9.35036 7.085 9.44884C7.03388 9.54732 7.00517 9.65456 7.00064 9.76385C6.99611 9.87314 7.0158 9.98213 7.05862 10.084C7.10144 10.1858 7.16638 10.2784 7.2494 10.3558L12.3654 15.9212C12.4455 16.0086 12.545 16.0788 12.657 16.127C12.7691 16.1751 12.8911 16.2 13.0146 16.2C13.138 16.2 13.26 16.1751 13.3721 16.127C13.4842 16.0788 13.5838 16.0086 13.6639 15.9212L18.7848 10.3558C18.919 10.2152 18.995 10.0348 18.9998 9.84685Z"
        fill="#716F6F"
      />
      <rect x="0.5" y="0.5" width="25" height="25" rx="3.5" stroke="#E2E2E2" />
    </svg>
  );
}

const EditResponses = ({queries}) => {
  const [open, setOpen] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [search, setSearch] = useState("");

  const filteredQueries = !search
    ? queries
    : queries.filter((query) =>
        query.query.toLowerCase().includes(search.toLowerCase())
      );

  const handleOpen = (value) => {
    setOpen(open === value ? -1 : value);
  };
  
  const downloadTranscript = async (conversation_id) => {
    const res = await getConversation(conversation_id)
    let blob = new Blob([res], {
      type: "text/plain;charset=utf-8"
    });
    FileSaver.saveAs(blob, `transcript-${new Date()}.txt`);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredQueries.slice(indexOfFirstItem, indexOfLastItem);
  const editedItems = currentItems.filter(query => query.edited);

  return (
    <>
      <Card className="bg-white p-[20px] rounded-[6px] border border-gray-500 ">
        <SearchInput search={search} setSearch={setSearch} />
        {filteredQueries && editedItems && editedItems.length > 0 ? (
          <>
            <CardBody className="px-0  overflow-scroll md:overflow-hidden">
              <table className="w-full table-auto text-left">
                <thead>
                  <tr>
                    <th
                      className={`flex h-[40px] items-center justify-between uppercase bg-gray-500 text-[14px] text-black leading-[24px] p-4`}
                    >
                      <p> Queries </p>
                      <div className="flex pr-16">
                        <p className=" mr-[20px]"> Time saved </p>
                        <p> Star Rating </p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((query, index) => {
                    if(!query.edited){
                      return null;
                    }
                    return (
                      <tr key={index}>
                        <td className="p-0 border-0 border-b border-gray-500">
                          <Accordion
                            open={open === index}
                            className={`${
                              open === index ? "bg-[#F9F8F8]" : "bg-white"
                            } `}
                            icon={<Icon id={index} open={open} />}
                          >
                            <AccordionHeader
                              onClick={() => handleOpen(index)}
                              className={`${
                                open === index
                                  ? "text-blue-800 font-semibold"
                                  : "text-gray-800 hover:text-blue-500 hover:underline font-medium"
                              }  px-4 text-[16px] border-0 `}
                            >
                              <div className="flex items-center justify-between w-full">
                                <div className="flex items-center space-x-2">
                                  <p>{query.input}</p>
                                </div>
                              </div>
                              <div className="flex items-center">
                                <p className="w-[120px] text-[16px] text-gray-800 font-light mr-6">
                                  15min
                                </p>
                                <img
                                  className="w-[100px] h-[16px]"
                                  src="/assets/icons/stars.png"
                                  alt="stars"
                                />
                              </div>
                            </AccordionHeader>
                            <AccordionBody className="pt-0">
                              <div className="bg-white border border-gray-500 p-4 mb-4 rounded-[6px]">
                                <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
                                  <div className="md:col-span-2">
                                    <h3 className="text-[16px] text-[#2C2C31] font-semibold leading-[24px] mb-[10px]">
                                      Response
                                    </h3>
                                    <div
                                      style={{ fontFamily: "IBM Plex Mono" }}
                                      className="text-[15px] bg-[#F8F8F8] mb-[20px] text-[#2C2C31] border rounded-[6px] p-4"
                                    >
                                      <pre
                                        style={{
                                          fontFamily: "IBM Plex Mono",
                                          whiteSpace: "pre-wrap",
                                        }}
                                        className="mb-4"
                                      >
                                        {query.output}
                                      </pre>
                                    </div>
                                    <div className="flex flex-wrap">
                                      <button 
                                       onClick={()=>downloadTranscript(query.conversation_id)}
                                      className="md:my-0 my-4 flex bg-transparent border-2 border-blue-500 py-[11px] px-[14px] rounded-[6px] uppercase text-[14px] leading-[18px] text-blue-500 font-semibold">
                                        <span className="mr-[10px]">
                                          <img
                                            className="w-[18px] h-[18px]"
                                            src="/assets/icons/download.svg"
                                            alt="download"
                                          />
                                        </span>
                                        <span>Download transcript</span>
                                      </button>
                                    </div>
                                  </div>
                                  <div>
                                    <h3 className="text-[14px] text-black uppercase font-semibold leading-[24px] mb-[10px]">
                                      sources
                                    </h3>
                                    {query.sources &&
                                      query.sources.length > 0 &&
                                      query.sources.map((source, index) => {
                                        return (
                                          <a
                                            key={index + 5}
                                            href={source}
                                            style={{ wordWrap: "break-word" }}
                                            className="block hover:underline text-[16px] text-blue-500 underline leading-[24px] mb-[10px]"
                                          >
                                            {source}
                                          </a>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </AccordionBody>
                          </Accordion>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={queries.length}
              paginate={setCurrentPage}
            />
          </>
        ) : (
          <>
            <div className="flex justify-center items-center">
              <p className="my-6">No data to display</p>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default EditResponses;
