import axios from "axios";
import { TEAM_ID, ORG_ID } from "../config/constants";

export const chatapi = async (prompt, reqType, conversation_id, cancelToken) => {
    let response;
    if (reqType === "mermaid") {
        response = await axios.post(process.env.REACT_APP_MiDDLEWARE_URL + "chat/query-chat", {
            widget_id:process.env.REACT_APP_WIDGET_ID,
            org_id:process.env.REACT_APP_ORG_ID,
            conversation_id,
            prompt:
                prompt +
                ` This is a request from a user to generate a mermaid.js diagram. You need to respond only with the markup code for the diagram. 
                    Dont add anything before or after the response. The diagram should be formatted correctly and eligible to be tested on mermaidjs. 
                    
                    Before responding check if your response has errors and if the code has any errors fix them. 
                    The final response needs to be perfect and work out of the box when tested with mermaid.js
                    Here are some examples and responses 
                     
                    Example Question 1 - Create a process diagram where a user needs to purchase a laptop in a company. 
                    The user raises a request and it is passed to his manager and then sent to purchasing for approval.
                    At both stages the request can be rejected and sent back the user and after final approval its sent to a vendor
        
                    Example Response 
                        graph LR
                          A[User Request] -->|Submit| B[Manager Review]
                          B -->|Approved| C[Purchasing]
                          C -->|Approved| D[Send to Vendor]
                          B -->|Rejected| A
                          C -->|Rejected| A
        
                    Example Question 2 - Describe the interactions between the user, task, project and progress when performing specific tasks, 
                    such as creating a new project in a sequence diagram
        
                    Example Response 
                        sequenceDiagram
                          participant User
                          participant Task
                          participant Project
                          participant Progress
        
                          User->>+Task: createNewProject(projectName)
                          Task->>+Project: createProject(projectName)
                          Task->>+Progress: createNewProgress(project)
                          Progress-->>-Task: progressCreated(progress)
                          Task-->>-User: projectCreated(project)
                    `,
        },
        {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
          },
        { cancelToken: cancelToken.token });
    } else if (reqType === "highcharts") {
        const ex = ` Please write JavaScript code for a Highcharts chart with a height of 400 pixels, the type should be with the height here is an example [{chart:{height:400, type:''}}], using 'highchartId' as the container ID.
         Exclude any explanations or backticks. Put the appropriate chart title. 
         Format the data as a CSV string with semicolons and newline characters as separators, enclosed in double quotes, like this example: "slice;val\n"2018";75\n"2019";50\n"2020";90\n"2021";60". Enclose the code in <script> tags. (It is just an example) [data: { "csv": \"\"slice\";\"val\"\n\"Not Pacman\";24\n\"Pacman\";76\"}]. 
         Everything should be pure text with double quotes.
         Enclose the code in <script> tags. the following is an example of how the highchartjs should look like:
         <script>
         Highcharts.chart('highchartId', {
             chart: {
                 "height": 400,
                 "type": "column"
         },
         credits: {
            enabled: false
        },
             title: {
                 text: "Weather in Morocco in the last 6 years"
         },
         
             xAxis: {
                 categories: ['2015', '2016', '2017', '2018', '2019', '2020']
         },
             yAxis: {
                 title: {
                     text: "Temperature (°C)"
                 }
         },
         data: {
          csv: "Year,Minimum temperature,Maximum temperature\n2015,11,26\n2016,12,28\n2017,8,24\n2018,14,30\n2019,7,25\n2020,10,27"
      },
             plotOptions: {
                 column: {
                     pointPadding: 0.2,
                     borderWidth: 0
                 }
         },
             series: [{
                 name: 'Minimum temperature',
                 data: [11, 12, 8, 14, 7, 10]
         }, {
                 name: 'Maximum temperature',
                 data: [26, 28, 24, 30, 25, 27]
         }]
         });
         </script>
         <script>
         Highcharts.chart('highchartId', {
             chart: {
                 height: 400,
                 type: 'line'
             },
             credits: {
                enabled: false
            },
             title: {
                 text: 'Death in Morocco in the Last 6 Years'
             },
            
             xAxis: {
                 categories: ['2015', '2016', '2017', '2018', '2019', '2020']
             },
             yAxis: {
                 title: {
                     text: 'Number of Deaths'
                 }
             },
             data: {
              csv: "Year,Deaths\n2015,26872\n2016,28530\n2017,29916\n2018,32453\n2019,31386\n2020,33266"
          },
             plotOptions: {
                 line: {
                     dataLabels: {
                         enabled: true
                     },
                     enableMouseTracking: false
                 }
             },
             series: [{
                 name: 'Deaths',
                 data: [26872, 28530, 29916, 32453, 31386, 33266]
             }]
         });
         </script>
         `;
        response = await axios.post(process.env.REACT_APP_MiDDLEWARE_URL + "chat/query-chat", {
            widget_id:process.env.REACT_APP_WIDGET_ID,
            org_id:process.env.REACT_APP_ORG_ID,
            prompt,
            conversation_id
        }, 
        {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
          },
        { cancelToken: cancelToken.token });
    } else {
            response = await axios.post(process.env.REACT_APP_MiDDLEWARE_URL + "chat/query-chat", {
                widget_id:process.env.REACT_APP_WIDGET_ID,
                org_id:process.env.REACT_APP_ORG_ID,
                prompt,
                conversation_id
            },
            {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
                },
              },
            { cancelToken: cancelToken.token });

    }
    return response.data;
};
