import React from 'react'

const RightElements = ({ handleClick, selected, text }) => {

  const isSelected = (input) => {
    return selected === input ? 'text-blue-500 font-semibold' : 'text-gray-900 font-medium';
  };


  return (
    <>
      <div onClick={(e) => handleClick(text, e)}
        className={` ${isSelected(text)} mt-2 flex items-center cursor-pointer py-1 2xl:p-4 px-[16px] rounded-[10px] bg-white`}>
        <h3 className="lg:text-[16px] text-xs ml-2 hover:text-blue-500">
          {text}</h3>
      </div>
    </>
  )
}

export default RightElements