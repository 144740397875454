import { useState, useEffect, useContext } from "react";
import LeftSection from "../components/sections/LeftSection";
import RightSection from "../components/sections/RightSection";
import ChatL from "../components/sections/ChatL";
import moment from "moment";
import ChatForm from "../components/sections/ChatForm";
import { handleRequest, parseAndSetBotResponse, parseAndSetUserMessage } from "../functions/submit";
import { useTranslation } from "react-i18next";
import { languageContext } from "../App";
import ChatHeader from "../components/layouts/ChatHeader";
import useEditLanguage from "../hooks/useEditLanguage";
import axios from 'axios';

const { CancelToken } = axios;


const ChatPage = () => {

    const [cancelToken, setCancelToken] = useState(null);
    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({ input: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [currectLang, setCurrentLang] = useState(i18n.language === "en-US" ? "en" : i18n.language);
    const { globalLang } = useContext(languageContext);
    

    const [conversationId, setConversationId] = useState(0);
    const { input } = formData;
    const [chatLog, setChatLog] = useState([]);
    const [errorSyntax, setErrorSyntax] = useState({});

    useEditLanguage(i18n, setCurrentLang);

    const newConversationId = () => {
        setConversationId(Math.floor(new Date() / 1000))
    }

    useEffect(() => {
        newConversationId()
    }, [])

    const newConversation = () => {
        window.location.href = "/";
    };



    const handleSubmit = async (data, e) => {
        e && e.preventDefault();
            setIsLoading(true);
            const { element, reqType } = parseAndSetUserMessage(data, setChatLog, input, setFormData, formData);

            if (cancelToken) {
                cancelToken.cancel();
              }
              const source = CancelToken.source();
              setCancelToken(source);
            try {
                const result = await handleRequest(data, element, reqType, parseInt(conversationId), 108, currectLang, source);
                await parseAndSetBotResponse(result, element, setChatLog, errorSyntax, setIsLoading, source);
            } catch (error) {
                if (axios.isCancel(error)) {
                    setIsLoading(false);
                    console.log('Request canceled');
                  } else {
                    setChatLog(chatLog => [...chatLog, {
                        role: "assistant",
                        errorEl: true
                    }]);
                    setIsLoading(false);
                    console.log(error);
                  }
              
            }
        
    };


    return (
        <>
            <main className="mx-auto md:px-[40px] px-[12px]">
                <div className="flex flex-col lg:flex-row" style={{ flexWrap: "wrap" }}>
                    {/* It is the left side that contains filters */}
                    <LeftSection  />
                    <section className="flex flex-col justify-between lg:w-3/5 w-full h-full">
                        <div className="flex flex-col h-full">
                            <div className="px-[20px] py-4 mx-[40px] mt-4  bg-white rounded-[6px]">
                               <ChatHeader
                                t={t}
                                isLoading={isLoading}
                                globalLang={globalLang}
                                newConversation={newConversation}
                               />
                                {/* Where we display the chat messages (what the user sent and the respose from backend) */}
                                <ChatL
                                    globalLang={globalLang}
                                    isLoading={isLoading}
                                    errorSyntax={errorSyntax}
                                    setErrorSyntax={setErrorSyntax}
                                    chatLog={chatLog}
                                />
                                {/* the form that has the submit function the send/receive requests/responses */}
                                <ChatForm
                                    t={t}
                                    globalLang={globalLang}
                                    handleSubmit={handleSubmit}
                                    isLoading={isLoading} 
                                    formData={formData}
                                    cancelToken={cancelToken}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                    </section>
                    {/* the section in the right that has prompts */}
                    <RightSection handleSubmit={handleSubmit} formData={formData} setFormData={setFormData} />
                </div >
            </main >
        </>
    )
}
export default ChatPage