import React from 'react'

const ChatHeader = ({ t, isLoading, globalLang, newConversation }) => {
    return (
        <div className="flex mb-6 items-center">
            <img className="w-[46px] h-[46px] mr-4 rounded-[4px]"
                src={`/assets/images/touchpoint.png`}
                alt="touchpoint pic" />
            <div className="w-full flex justify-between">
                <div className="flex flex-col" >
                    <h4 style={globalLang ? { direction: "rtl" } : {}}
                        className="text-[15px] font-semibold text-blue-800 leading-[22px] mb-[2px]">{t("chatbox.h1")}</h4>
                    <p className="text-[14px] font-medium text-gray-800 leading-[18px]]">
                        <span>{isLoading ? `${t("chatbox.p2")}` : `${t("chatbox.p1")}`}</span>
                    </p>
                </div>
                {/* <div>
                    <button onClick={newConversation} style={{ lineHeight: "26px" }} className="mt-2 text-center lg:mt-0 flex items-center text-md border-[2px] border-blue-500 text-blue-500 rounded-full px-4 py-2">
                        <span className="xl:mr-2 plusSvg">
                            <svg className="" width="16" height="16" viewBox="0 0 16 16" fill={`#009df1`} xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.208 16V9.792H0V6.176H6.208V0H9.824V6.176H16V9.792H9.824V16H6.208Z" />
                            </svg>
                        </span>
                        <span className="xl:flex hidden">{t("controls.p3")}</span>
                    </button>
                </div> */}
            </div>
        </div>
    )
}

export default ChatHeader