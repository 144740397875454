import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import Header from "./components/layouts/Header";
import ChatPage from "./pages/ChatPage";
import useCache from "./hooks/useCache";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InsightsPage from "./pages/InsightsPage";
import Steps from "./pages/addOnChat/Steps";

export const languageContext = createContext();

function App() {
  const { i18n } = useTranslation();
  const [globalLang, setglobalLang] = useState(false);

  useEffect(() => {
    if (
      i18n.language === "hebrew" 
    ) {
      setglobalLang(true);
    } else {
      setglobalLang(false);
    }
  }, [i18n.language]);

  useCache();

  return (
    <>
      <languageContext.Provider value={{ globalLang, setglobalLang }}>
        <ToastContainer />
        <Router>
          <div className="">
            <div className={`bg-[#F6F6F6] font-montserrat overflow-y-hidden`}>
              <Routes>
                <Route path="/" element={<Steps />} />
                <Route
                  path="*"
                  element={
                    <>
                      <Header />
                     <Routes>
                      <Route path="/chat" element={<ChatPage />} />
                      <Route path="/insights" element={<InsightsPage />} />
                     </Routes>
                    </>
                  }
                />
                <Route path="/*" element={<Navigate to="/" replace />} />
              </Routes>
            </div>
          </div>
        </Router>
      </languageContext.Provider>
    </>
  );
}

export default App;
