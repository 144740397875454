import { useEffect, useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Spinner
} from "@material-tailwind/react";
import { editResponse } from "../../api/responseService";
import MDEditor from "@uiw/react-md-editor";


export default function EditModal({open, setOpen, clickedResponse, getAllQueries}) { 
    const [EditResponse, setEditResponse] = useState("")
    const [sources, setSources] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

      useEffect(() => {
        if (clickedResponse) {
          setEditResponse(clickedResponse.response);
          setSources(clickedResponse.sources || []);
        }
      }, [clickedResponse]);

      const handleAddSource = () => {
        setSources(prevSources => [...prevSources, '']);
      };

      const handleRemoveSource = (indexToRemove) => {
        setSources(prevSources => prevSources.filter((source, index) => index !== indexToRemove));
      };

      const handleSourceChange = (indexToChange, newValue) => {
        setSources(prevSources => prevSources.map((source, index) => index === indexToChange ? newValue : source));
      };

      const handleSubmit = async () => {
        setIsLoading(true);
        try {
          await editResponse(clickedResponse.prompt, EditResponse,
            clickedResponse.query_id);
          await getAllQueries()
          setIsLoading(false);
          setOpen(false);
        } catch(error){
          console.log(error);
          setIsLoading(false);
        }
      };
 
  return (
    <>
   
      <Dialog open={open} className="rounded-[10px] w-4/5 max-w-full md:max-w-[40%] md:w-2/5 p-[40px]">
      {isLoading && <div className="flex items-center justify-center absolute top-0 left-0 z-[5] w-full h-full bg-black opacity-25">
        <Spinner className="h-24 w-24 z-[15]" />;
      </div>}
       <div className="flex items-center mb-[30px]  justify-between">
         <DialogHeader className="text-[#2C2C31] font-semibold text-[30px]">Edit response</DialogHeader>
       <span>
        <img onClick={()=>setOpen(false)} className="cursor-pointer" src="/assets/icons/close.svg" alt="close" />
       </span>
       </div>
        <DialogBody className="overflow-y-scroll h-[30rem] overflow-x-hidden">
        <div className="mb-[20px]">
            <label htmlFor="email" className="block mb-[10px] text-[15px] font-semibold text-gray-900">Question</label>
            <input type="text" disabled
            value={clickedResponse?.prompt}
            className="border border-gray-500 outline-0 text-gray-800 rounded-[8px] py-[14px] px-4 w-full" />
        </div>
        <div className="mb-[20px]">
            <label htmlFor="edit" className="block mb-[10px] text-[15px] font-semibold text-gray-900">Answer</label>
            <MDEditor preview="edit" height={400} value={EditResponse} onChange={setEditResponse} />
        </div>
        <div className="">
            <label htmlFor="sources" className="block mb-[10px] text-[15px] font-semibold text-gray-900">Sources</label>
            {sources.map((source, index) => (
              <div key={index} className="flex">
                <input
                  type="text"
                  value={source}
                  onChange={(e) => handleSourceChange(index, e.target.value)}
                  className="mr-[20px] mb-[8px] border border-gray-500 outline-0 text-gray-800 rounded-[8px] py-[14px] px-4 w-full"
                />
                <div className="flex">
                  <img
                    onClick={() => handleRemoveSource(index)}
                    className="cursor-pointer mr-[10px]"
                    src="/assets/icons/minus.svg"
                    alt="minus"
                  />
                  <img
                    onClick={handleAddSource}
                    className="cursor-pointer"
                    src="/assets/icons/plus.svg"
                    alt="plus"
                  />
                </div>
              </div>
            ))}
          </div>
        </DialogBody>
        <DialogFooter className="flex justify-between">
          <button
            className="border-2 mb-2 md:mb-0 font-bold bg-transparent rounded-[6px] px-[30px] py-[11px] border-black text-black"
            onClick={()=>setOpen(false)}
          >
            <span>Cancel</span>
          </button>
          <button 
          className="border-2 font-bold bg-transparent rounded-[6px] px-[30px] py-[11px] border-blue-500 text-blue-500"
           onClick={handleSubmit}>
            <span>Save</span>
          </button>
        </DialogFooter>
      </Dialog>
    </>
  );
}