import { useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Spinner,
} from "@material-tailwind/react";
import { addData } from "../../api/ListDocsService";
import { toast } from "react-toastify";

export default function AddWebDataModal({ open, setOpen, title, isUpdate, setIsUpdate }) {
  const [url, setUrl] = useState("");
  const [titleUrl, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    // Check if the URL is valid
    const pattern = new RegExp(
      "^https:\\/\\/" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,})" + // domain name and extension
        "(\\:\\d+)?" + // port
        "(\\/[-a-z\\d%_.~+]*)*" + // path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    if (!pattern.test(url)) {
      alert("Please enter a valid URL starting with https://");
      return;
    }
    setIsLoading(true);
    try {
     const res =  await addData(url, titleUrl, Date.now());
     if(res){
      setIsUpdate(!isUpdate);
      setIsLoading(false);
      setOpen(false);
      setTitle("");
      setUrl("");
      toast.success("url added successfully");
     }
      // setTimeout(function(){ window.location.reload(); }, 2000);
    } catch (error) {
      toast.error(error.response.data);
      setOpen(false);
      setIsLoading(false);
      setTitle("");
      setUrl("");
    }
  };

  return (
    <>
      <Dialog
        open={open}
        className="rounded-[10px] w-4/5 max-w-full md:max-w-[40%] md:w-2/5 p-[40px]"
      >
        {isLoading && (
          <div className="flex items-center justify-center absolute top-0 left-0 z-[5] w-full h-full bg-black opacity-25">
            <Spinner className="h-24 w-24 z-[15]" />;
          </div>
        )}
        <div className="flex items-center mb-[30px]  justify-between">
          <DialogHeader className="text-[#2C2C31] font-semibold text-[30px]">
            {title}
          </DialogHeader>
          <span>
            <img
              onClick={() => setOpen(false)}
              className="cursor-pointer"
              src="/assets/icons/close.svg"
              alt="close"
            />
          </span>
        </div>
        <DialogBody className=" overflow-x-hidden">
          <div className="mb-6">
            <label
              htmlFor="title"
              className="block mb-[10px] text-[15px] font-semibold text-gray-900"
            >
              Title
            </label>
            <input
              name="titleUrl"
              value={titleUrl}
              onChange={(e) => setTitle(e.target.value)}
              className="bg-[#FBFBFB] resize-none border border-gray-500 outline-0 text-gray-800 rounded-[8px] py-[14px] px-4 w-full"
              required
            />
          </div>
          <div className="mb-[20px]">
            <label
              htmlFor="edit"
              className="block mb-[10px] text-[15px] font-semibold text-gray-900"
            >
              Web URL
            </label>
            <input
              name="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              className="bg-[#FBFBFB] resize-none border border-gray-500 outline-0 text-gray-800 rounded-[8px] py-[14px] px-4 w-full"
              required
            />
          </div>
        </DialogBody>
        <DialogFooter className="flex justify-between">
          <button
            className="border-2 mb-2 md:mb-0 font-bold bg-transparent rounded-[6px] px-[30px] py-[11px] border-black text-black"
            onClick={() => setOpen(false)}
          >
            <span>Cancel</span>
          </button>
          <button
            className="border-2 font-bold bg-transparent rounded-[6px] px-[30px] py-[11px] border-blue-500 text-blue-500"
            onClick={handleSubmit}
          >
            <span>Save</span>
          </button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
