import React from "react";
import { insights } from "../../config/constants";

const Insights = () => {
  return (
    <div className="bg-white p-[20px] rounded-[6px] border border-gray-500">
      <div className="flex justify-between mb-4">
        <h3 className='text-[14px] text-gray-900 uppercase font-bold leading-[18px]'>
          Insights
        </h3>
      </div>
      {insights.map((item, index) => {
        return (
          <div key={index} className="flex border-b border-gray-500  cursor-pointer py-[10px]">
            <img
              className="mr-[10px]"
              src="/assets/icons/arrows.svg"
              alt="right double arrow"
            />
            <p
            style={{fontWeight:500}}
             className="text-md leading-[24px] text-[#444444] hover:text-blue-500 hover:underline">
             {item}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Insights;
