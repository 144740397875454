import axios from "axios";
import { TEAM_ID, ORG_ID } from "../config/constants";


export const generateImage = async (prompt, userId, cancelToken) => {
    const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "dall-e", {
        user_id: userId,
        org_id:ORG_ID,
        team_id: TEAM_ID,
        prompt
    }, { cancelToken: cancelToken.token });
    return response.data;
}