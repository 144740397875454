import { useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,Typography,
  Spinner,
} from "@material-tailwind/react";
import { toast } from "react-toastify";
import { flaggingResponse } from "../../api/flagService";

export default function FlagComment({ open, setOpen, clickedResponse, getAllQueries }) {
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await flaggingResponse(
        clickedResponse.query_id,
        comment
      );
      if (res) {
        toast.success("Successful Operation !", {
          position: toast.POSITION.BOTTOM_CENTER,
          theme: "dark",
        });
        setIsLoading(false);
        setComment("");
        setOpen(false);
        await getAllQueries()
        // setTimeout(()=>{
        //   window.location.reload();
        // },2000)
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        className="rounded-[10px] w-4/5 max-w-full md:max-w-[40%] md:w-2/5 p-[40px]"
      >
        {isLoading && (
          <div className="flex items-center justify-center absolute top-0 left-0 z-[5] w-full h-full bg-black opacity-25">
            <Spinner className="h-24 w-24 z-[15]" />;
          </div>
        )}
        <div className="flex items-center mb-[30px]  justify-between">
          <DialogHeader className="text-[#2C2C31] font-semibold text-[30px]">
            {!clickedResponse?.flagged  ? "Flag Response" : "Unflag Response"}
          </DialogHeader>
          <span>
            <img
              onClick={() => setOpen(false)}
              className="cursor-pointer"
              src="/assets/icons/close.svg"
              alt="close"
            />
          </span>
        </div>

        {!clickedResponse?.flagged ? (
          <DialogBody className=" overflow-x-hidden">
            <div className="mb-[20px]">
              <label
                htmlFor="comment"
                className="block mb-[10px] text-[15px] font-semibold text-gray-900"
              >
                User Comment
              </label>
              <input
                type="text"
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                className="border border-gray-500 outline-0 text-gray-800 rounded-[8px] py-[14px] px-4 w-full"
                required
              />
            </div>
          </DialogBody>
        ) : (
          <DialogBody divider className="grid place-items-center gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-16 w-16 text-red-500"
            >
              <path
                fillRule="evenodd"
                d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
                clipRule="evenodd"
              />
            </svg>
            <Typography color="red" variant="h4">
              You should read this!
            </Typography>
            <Typography className="text-center font-normal">
              Are you sure you wanna unflag this query.
            </Typography>
          </DialogBody>
        )}

        <DialogFooter className="flex justify-between">
          <button
            className="border-2 mb-2 md:mb-0 font-bold bg-transparent rounded-[6px] px-[30px] py-[11px] border-black text-black"
            onClick={() => setOpen(false)}
          >
            <span>Cancel</span>
          </button>
          <button
            className="border-2 font-bold bg-transparent rounded-[6px] px-[30px] py-[11px] border-blue-500 text-blue-500"
            onClick={handleSubmit}
          >
            <span>Save</span>
          </button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
