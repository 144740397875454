import React from "react";
import { CardFooter } from "@material-tailwind/react";

const Pagination = ({ currentPage, itemsPerPage, totalItems, paginate }) => {
  const pageNumbers = [];
  
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const startEntry = (currentPage - 1) * itemsPerPage + 1;
  const endEntry = Math.min(currentPage * itemsPerPage, totalItems);

  let pages = pageNumbers;

  // Constants for pagination
  const pageNeighbours = 1; // pages to show on either side of current page
  const totalNumbers = pageNeighbours * 2 + 3; // total page numbers to show at once
const totalBlocks = totalNumbers + 2; // total blocks including next and previous

if (pageNumbers.length > totalBlocks) {
  const startPage = Math.max(2, currentPage - pageNeighbours);
  const endPage = Math.min(pageNumbers.length - 1, currentPage + pageNeighbours);
  pages = range(startPage, endPage);
  
  const hasLeftSpill = startPage > 2;
  const hasRightSpill = (pageNumbers.length - endPage) > 1;
  const spillOffset = totalNumbers - (pages.length + 1);
  
  switch (true) {
    // handle: (1) < {5 6} [7] {8 9} (10)
    case (hasLeftSpill && !hasRightSpill): {
      const extraPages = range(startPage - spillOffset, startPage - 1);
      pages = [1, ...extraPages, ...pages, pageNumbers.length]; // always include last page
      break;
    }

    // handle: (1) {2 3} [4] {5 6} > (10)
    case (!hasLeftSpill && hasRightSpill): {
      const extraPages = range(endPage + 1, endPage + spillOffset);
      pages = [1, ...pages, ...extraPages, pageNumbers.length]; // always include first page
      break;
    }

    // handle: (1) < {4 5} [6] {7 8} > (10)
    case (hasLeftSpill && hasRightSpill):
    default: {
      pages = [1, ...pages, pageNumbers.length]; // include first and last pages by default
      break;
    }
  }
}

  return (
    <CardFooter className="flex items-center justify-center md:justify-between border-t border-blue-gray-50 p-4">
      <div>
        <p className="text-[#333] font-medium text-[15px]">
        Showing {startEntry} to {endEntry} of {totalItems} entries
        </p>
      </div>
      <div className="flex">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="flex items-center font-semibold text-black rounded-[6px] p-[10px] border border-gray-[500]"
        >
          <span className="rotate-180">
            <img src="/assets/icons/next.svg" alt="next" />
          </span>
          <span className="ml-[10px]"> Previous </span>
        </button>
        <div className="hidden md:flex items-center mx-[20px]">
          {pages.map((number) => (
            <button
              key={number}
              className={`font-semibold mr-[8px] rounded-[6px] p-[6px] border border-gray-[500] w-[38px] ${currentPage === number ? 'bg-blue-500 text-white' : 'text-black'}`}
              onClick={() => paginate(number)}
            >
              {number}
            </button>
          ))}
        </div>
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === pageNumbers.length}
          className="ml-[10px] md:ml-0 flex items-center font-semibold text-black rounded-[6px] p-[10px] border border-gray-[500]"
        >
          <span className="mr-[10px]">Next</span>
          <span>
            <img src="/assets/icons/next.svg" alt="next" />
          </span>
        </button>
      </div>
    </CardFooter>
  );
};

// Helper function
function range(from, to, step = 1) {
  let i = from;
  const range = [];
  
  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
}

export default Pagination;
