import { NavLink } from "react-router-dom";

const Front = ({ step, setStep, toggleChat, showChat }) => {
  const handleClick = () => {
    setStep(step + 1);
  };
  return (
    <div className="divSmallChat">
      <div className="fixed bottom-[20px] right-[20px] z-[9999] font-montserrat">
        <div
          className={`2xl:h-[600px] h-[540px] chat-sm flex-col shadow-xl justify-between rounded-[16px] ${showChat ? "visible chat-animation-open" : "chat-animation-close"
            }`}
        >
          <div className="px-4 pt-2 2xl:pb-0 pb-4 h-full bg-blue-800 rounded-tl-[16px] rounded-tr-[16px]">
            <div className="flex h-full flex-col">
              <img
                className="mt-4 mb-[30px] w-[182px] h-[42px]"
                src="/assets/images/touchpointlogo.png"
                alt="touchpoint logo"
              />
             <div>
            <div>
            <h3 className="flex text-[28px] mb-[10px] font-bold text-white">
            Hi,
              </h3>
            <h3 className="flex text-[28px] mb-[30px] font-bold text-white">
            How can we help?
              </h3>
              <button className="py-[11px] px-[16px] text-white w-full text-[16px] font-bold mb-[40px] rounded-[6px] border-2 border-white text-center">Contact Support</button>
              <div className="white-boxes mt-4">
                <div
                  onClick={handleClick}
                  className="cursor-pointer flex items-center justify-between bg-white rounded-lg p-4"
                >
                  <div className="texts ">
                    <h4 className="text-[16px] font-bold text-blue-500">
                      Send us a message
                    </h4>
                    <p className="text-[14px] text-gray-800">
                    We'll respond immedialtly
                    </p>
                  </div>
                  <SendIcon />
                </div>
              </div>
            </div>
             </div>
            </div>
          </div>
          <div className="bg-white rounded-bl-[16px] rounded-br-[16px]">
            <div className="bg-white px-4 py-2">
              <div className="grid grid-cols-2 gap-4 text-xs">
                <NavLink
                  to="/chat"
                  className="pt-2 cursor-pointer svg-container text-gray-800 hover:text-blue-400 flex flex-col justify-center items-center text-center"
                >
                  <Resource />
                  <p className="mt-2 text-[14px]">Resources</p>
                </NavLink>
                <a rel="noreferrer" href="https://docs.touchpointsoftware.com/index.html?_ga=2.112643716.1644764912.1687802220-1940633695.1687334516" target="_blank" className="pt-2 cursor-pointer text-gray-800 svg-container hover:text-blue-400 flex flex-col justify-center items-center text-center">
                  <CallUs />
                  <p className="mt-2 text-[14px]">Contact Support</p>
                </a>
              </div>
            </div>
            <div className="flex justify-center items-center py-4">
              <img
                className="w-[160px]"
                src="/assets/images/zuro.png"
                alt="powered by zuro"
              />
            </div>
          </div>
        </div>
        {!showChat ? (
          <div className="mt-2" onClick={toggleChat}>
            <div className="chat-button rounded-full">
              <div className="flex justify-center items-center bg-blue-800 rounded-full h-[50px] w-[50px]">
                <img
                  className=""
                  src="/assets/images/mini.png"
                  alt="open chat"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-2 w-full" onClick={toggleChat}>
            <div className="chat-button rounded-full w-full">
              <div className="flex justify-center items-center bg-blue-800 rounded-full h-[50px] w-[50px]">
                <img
                  className="-rotate-90"
                  src="/assets/icons/arrowLeft.svg"
                  alt="close chat"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Front;

const Resource = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9535 3.83721V18.1628C19.9535 20.282 18.2354 22 16.1163 22H3.83721C1.71805 22 0 20.282 0 18.1628V3.83721C0 1.71805 1.71805 0 3.83721 0H16.1163C18.2354 0 19.9535 1.71805 19.9535 3.83721ZM18.4186 3.83721C18.4186 2.5653 17.3882 1.53488 16.1163 1.53488H3.83721C2.5653 1.53488 1.53488 2.5653 1.53488 3.83721V18.1628C1.53488 19.4347 2.5653 20.4651 3.83721 20.4651H16.1163C17.3882 20.4651 18.4186 19.4347 18.4186 18.1628V3.83721Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.97747 4.09375C10.4011 4.09375 10.7449 4.43756 10.7449 4.86119C10.7449 5.28482 10.4011 5.62863 9.97747 5.62863H4.86119C4.43756 5.62863 4.09375 5.28482 4.09375 4.86119C4.09375 4.43756 4.43756 4.09375 4.86119 4.09375H9.97747Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0938 8.18555C15.5174 8.18555 15.8612 8.52936 15.8612 8.95299C15.8612 9.37662 15.5174 9.72043 15.0938 9.72043H4.86119C4.43756 9.72043 4.09375 9.37662 4.09375 8.95299C4.09375 8.52936 4.43756 8.18555 4.86119 8.18555H15.0938Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0938 12.2793C15.5174 12.2793 15.8612 12.6231 15.8612 13.0467C15.8612 13.4704 15.5174 13.8142 15.0938 13.8142H4.86119C4.43756 13.8142 4.09375 13.4704 4.09375 13.0467C4.09375 12.6231 4.43756 12.2793 4.86119 12.2793H15.0938Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0938 16.3721C15.5174 16.3721 15.8612 16.7159 15.8612 17.1395C15.8612 17.5631 15.5174 17.907 15.0938 17.907H4.86119C4.43756 17.907 4.09375 17.5631 4.09375 17.1395C4.09375 16.7159 4.43756 16.3721 4.86119 16.3721H15.0938Z"
      />
    </svg>
  );
};

const CallUs = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.2923 15.8356C21.2535 15.8033 16.8564 12.6705 15.6691 12.8663C15.0964 12.9675 14.7693 13.3584 14.113 14.1401C14.0074 14.2663 13.7529 14.5677 13.5564 14.7825C13.1416 14.6474 12.737 14.4827 12.3457 14.2897C10.3257 13.3063 8.69366 11.6743 7.71027 9.65433C7.5173 9.26304 7.35261 8.85843 7.21747 8.4436C7.43307 8.24633 7.7352 7.99187 7.86427 7.88333C8.64233 7.23067 9.03247 6.90287 9.13367 6.3294C9.3412 5.14213 6.19667 0.746533 6.1644 0.706933C6.0212 0.503841 5.83469 0.335078 5.61834 0.212826C5.40199 0.0905734 5.16119 0.0178824 4.91333 0C3.6388 0 0 4.71973 0 5.5154C0 5.5616 0.0667334 10.2579 5.85787 16.1487C11.7421 21.9333 16.4384 22 16.4846 22C17.2795 22 22 18.3612 22 17.0867C21.9819 16.8388 21.9091 16.598 21.7867 16.3816C21.6643 16.1653 21.4955 15.9788 21.2923 15.8356ZM16.4032 20.5289C15.7667 20.4747 11.8213 19.9547 6.89333 15.114C2.02913 10.1618 1.5224 6.20987 1.4718 5.59753C2.43304 4.08881 3.59391 2.71702 4.92287 1.51947C4.9522 1.5488 4.99107 1.5928 5.04093 1.65C6.06014 3.0413 6.93812 4.53073 7.66187 6.0962C7.42651 6.33297 7.17774 6.55603 6.9168 6.76427C6.51214 7.0726 6.14055 7.42207 5.808 7.80707C5.75173 7.88602 5.71167 7.97534 5.69015 8.06988C5.66863 8.16441 5.66606 8.26227 5.6826 8.3578C5.83779 9.03007 6.07549 9.68058 6.39027 10.2945C7.51805 12.6104 9.38943 14.4815 11.7055 15.609C12.3193 15.9242 12.9698 16.1622 13.6422 16.3174C13.7377 16.3339 13.8356 16.3314 13.9301 16.3099C14.0247 16.2883 14.114 16.2483 14.1929 16.192C14.5793 15.8581 14.93 15.485 15.2394 15.0788C15.4697 14.8045 15.7769 14.4386 15.8935 14.3352C17.463 15.0582 18.9557 15.9373 20.3493 16.9591C20.4101 17.0104 20.4534 17.05 20.482 17.0757C19.2844 18.405 17.9123 19.5662 16.4032 20.5275V20.5289Z" />
      <path d="M11.7778 5.55556C13.0151 5.55679 14.2013 6.04885 15.0762 6.92375C15.9511 7.79866 16.4432 8.98492 16.4444 10.2222C16.4444 10.4285 16.5264 10.6263 16.6722 10.7722C16.8181 10.9181 17.0159 11 17.2222 11C17.4285 11 17.6263 10.9181 17.7722 10.7722C17.9181 10.6263 18 10.4285 18 10.2222C17.9981 8.57256 17.342 6.99098 16.1755 5.82449C15.009 4.658 13.4274 4.00185 11.7778 4C11.5715 4 11.3737 4.08194 11.2278 4.22781C11.0819 4.37367 11 4.5715 11 4.77778C11 4.98406 11.0819 5.18189 11.2278 5.32775C11.3737 5.47361 11.5715 5.55556 11.7778 5.55556Z" />
      <path d="M11.7857 1.57143C14.0771 1.57413 16.2739 2.48558 17.8942 4.10585C19.5144 5.72611 20.4259 7.92289 20.4286 10.2143C20.4286 10.4227 20.5114 10.6225 20.6587 10.7699C20.8061 10.9172 21.0059 11 21.2143 11C21.4227 11 21.6225 10.9172 21.7699 10.7699C21.9172 10.6225 22 10.4227 22 10.2143C21.9969 7.50625 20.9197 4.91001 19.0049 2.99514C17.09 1.08027 14.4938 0.00311927 11.7857 0C11.5773 0 11.3775 0.0827804 11.2301 0.23013C11.0828 0.37748 11 0.57733 11 0.785714C11 0.994099 11.0828 1.19395 11.2301 1.3413C11.3775 1.48865 11.5773 1.57143 11.7857 1.57143Z" />
    </svg>
  );
};
const SendIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" rx="15" fill="#009df1" />
      <g clipPath="url(#clip0_543_565)">
        <path d="M8.1915 21.3611C8.07646 21.8897 8.48233 22.1135 8.85894 21.9447L21.5871 15.4181H21.5884C21.7402 15.3325 21.8284 15.1782 21.8284 15.0002C21.8284 14.822 21.7402 14.6676 21.5884 14.582H21.5871L8.85894 8.05537C8.48233 7.8865 8.07646 8.11035 8.1915 8.63901C8.19928 8.67482 8.95176 12.0267 9.36089 13.8496L16.0052 15.0001L9.36089 16.1505C8.95176 17.9733 8.19925 21.3252 8.1915 21.3611Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_543_565">
          <rect width="14" height="14" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};
