import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { countries } from "../../config/constants";
import useLanguage from "../../hooks/useLanguage";
import { useState } from "react";
import SupportBtn from "./SupportBtn";

const ArrowDown = () => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.00027546 1.32915C-0.00492567 1.10904 0.0636256 0.892896 0.196504 0.71076C0.329383 0.528624 0.520021 0.389549 0.741744 0.31287C0.963468 0.236191 1.20532 0.225719 1.43373 0.282908C1.66214 0.340098 1.86565 0.462108 2.01612 0.631976L7.9816 7.1134L13.945 0.631977C14.0378 0.514471 14.1556 0.416062 14.2911 0.342914C14.4266 0.269766 14.577 0.223454 14.7327 0.206878C14.8883 0.190302 15.0459 0.20382 15.1957 0.246585C15.3455 0.289349 15.4842 0.360439 15.6032 0.455403C15.7221 0.550367 15.8185 0.667159 15.8867 0.798465C15.9548 0.92977 15.9931 1.07276 15.9992 1.21848C16.0052 1.3642 15.9789 1.50952 15.9218 1.64532C15.8648 1.78112 15.7782 1.90449 15.6675 2.0077L8.84619 9.42835C8.73935 9.54488 8.60673 9.63845 8.45727 9.70262C8.30782 9.76678 8.14514 9.80001 7.98055 9.80001C7.81596 9.80001 7.65328 9.76678 7.50383 9.70262C7.35437 9.63845 7.22162 9.54488 7.11478 9.42835L0.28694 2.0077C0.108053 1.82025 0.00661317 1.57981 0.00027546 1.32915Z"
        fill="white"
      />
    </svg>
  );
};

const Header = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [isClicked, setIsClicked] = useState(false);

  function getFlag(countries, selectedCountry) {
    const country = countries.find(function (country) {
      return (
        country.name === selectedCountry || country.code === selectedCountry
      );
    });
    return country ? country.flag : undefined;
  }

  const { selectedCountry, setSelectedCountry } = useLanguage(
    i18n,
    getFlag,
    countries
  );

  const handleClick = (code, name) => {
    setLanguage(code);
    i18n.changeLanguage(code);
    setSelectedCountry({ name: name, flag: getFlag(countries, name) });
    setIsClicked(false);
  };
  return (
    <>
      <header className="bg-blue-500 h-[74px] mb-[40px]">
        <nav className="dashNav mx-auto flex justify-center items-center h-full 2xl:py-4 py-3 px-[40px]">
          <div className="flex justify-between w-full items-center">
            <div className=" cursor-pointer">
              <div
                className="flag-dropdown px-[14px] py-[15px] h-[42px] bg-blue-800 rounded-[4px]"
                onMouseEnter={() => setIsClicked(true)}
                onMouseLeave={() => setIsClicked(false)}
              >
                <div className="selected-item">
                  <img
                    className={`mr-[6px] w-[22px] h-[22px] rounded-full`}
                    src={`${selectedCountry.flag}`}
                    alt={selectedCountry.name}
                  />
                  <p className="capitalize text-gray-200 mr-[10px] text-md font-medium">
                    {selectedCountry.name}
                  </p>
                  <ArrowDown />
                </div>
                {isClicked && (
                  <ul
                    className="dropdown-list md:left-0 md:right-0"
                    style={{ width: "250px", zIndex: "20" }}
                  >
                    {countries.map((country) => (
                      <li
                        key={country.name}
                        className="hover:bg-[#f5f5f5] mb-2"
                        onClick={() => handleClick(country.code, country.name)}
                      >
                        <img
                          src={`${country.flag}`}
                          alt={country.name}
                          className={`mr-2 w-[18px] h-[12px]`}
                        />
                        <span className="text-[16px] text-[#494949]">
                          {country.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <Link to="/">
              <img
                className="w-[150px] md:w-[190px]"
                src="/assets/images/copilot.png"
                alt="touchpoint logo"
              />
            </Link>
            <SupportBtn />
          </div>
        </nav>
      </header>
    </>
  );
};
export default Header;
