import { useEffect, useState } from "react";

const useList = (type, list, isUpdate) => {
    const [youtubeUrls, setYoutubeUrls] = useState([]);
    const [fileUrls, setFileUrls] = useState([]);
    const [restData, setRestData] = useState([]);


    useEffect(() => {
        if(type === "videos" && list.length > 0){
            setYoutubeUrls(list.filter(item => item.url && typeof item.url === 'string' && item.url.includes('vimeo')));
        } else if(type === "pdf" && list.length > 0){
            setFileUrls(list.filter(item => 
                item.url && typeof item.url === 'string' && (item.url.endsWith('.pdf') || item.url.endsWith('.doc') || item.url.endsWith('.docx') || item.url.endsWith('.xls') || item.url.endsWith('.xlsx'))
            ))
        } else if(type==="html" && list.length > 0) {
            const tempRestData = list.filter(item => item.url && typeof item.url === 'string' && item.url.endsWith('.html'));
            setRestData(tempRestData);
        }
    }, [type, list, isUpdate]);

    if(type === "videos"){
        return youtubeUrls;
    } else if(type === "pdf"){
        return fileUrls;
    } else {
        return restData;
    }
};

export default useList;
