import {  Card, CardHeader } from "@material-tailwind/react";
import useList from "../../hooks/useList";
import { SearchIcon } from "../Svgs/Svgs";
import { ConfirmationModal } from "../Modals/ConfirmationModal";
import { useState } from "react";
import Pagination from "../layouts/Pagination";
import AddWebDataModal from "../Modals/AddWebDataModal";

const Videos = ({list, isUpdate}) => {

  const videos = useList("videos", list, isUpdate);
  const [open, setOpen]=useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [addWebDataModal, setAddWebDataModal] = useState(false);
  const [clickedResponse, setClickedResponse] = useState(null);

  const itemsPerPage = 10;
  const filteredData = !search 
  ? videos 
  : videos.filter(item => item.title.toLowerCase().includes(search.toLowerCase()));


  const [clickedFile, setClickedFile]=useState(null);

  const handleOpen = (file) =>{
    setClickedFile(file);
    setOpen(!open)
  };
  
  const handleOpenModal = () => {
    setAddWebDataModal(!addWebDataModal);
    setClickedResponse({
      prompt: 'prompt',
      response: 'response',
      query_id: 'query_id',
      sources: ['sources'],
    });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
    <ConfirmationModal open={open} setOpen={setOpen} clickedFile={clickedFile} />
      <Card className="bg-white p-[20px] my-[20px] rounded-[6px] border border-gray-500 ">
        <div className="mb-[20px] mt-[10px]">
          <h2 className="font-bold text-[14px] leading-[18px] uppercase text-black">
           Videos
          </h2>
        </div>
        <div className="flex justify-between items-center">
        <label className="relative">
        <input
          type="text"
          value={search}
          name="search"
          onChange={(e)=>setSearch(e.target.value)}
          placeholder="Search"
          className="border border-gray-500 mt-[10px] text-[15px]
          placeholder-gray-800 outline-0 text-gray-800 h-[42px] rounded-[8px] py-[9px] px-[12px] w-[518px]"
        />
        <SearchIcon />
      </label>
          <div>
            <button
     
            className="h-[40px] flex items-center border-2 border-blue-500
             text-blue-500 font-semibold text-[14px] rounded-[6px] px-4 py-[15px]">
              Add Video
            </button>
          </div>
        </div>
        {filteredData && filteredData.length > 0 ? <>
        
          <div className="grid xl:grid-cols-5 lg:grid-cols-2 grid-cols-2 gap-4 mt-[20px]">
           {
            currentItems.map((video, index)=>{
                return <div key={index} className="bg-[#F8F8F8] flex flex-col justify-between overflow-hidden">
              <div>
              <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="m-0 rounded-none"
                >
                    <img
                    className="px-4 pt-4 pb-[20px] w-full object-cover"
                    src="/assets/images/vimeo.png"
                    alt={video.title}
                    />
                </CardHeader>
                <div className="cursor-pointer px-4">
                    <a href={video.url} target="_blank" rel="no-referrer" className="text-[15px] font-medium leading-[18px] text-gray-800">{video.title}</a>
                    <p className="text-[15px] font-medium text-gray-800 my-[8px]">{video.description}</p>
                </div>
              </div>
                <div className="flex px-4 pb-4">
                   <a href={video.url} target="_blank" className="text-[15px] font-medium leading-[22px] text-blue-500 underline cursor-pointer" download>
                      View
                    </a>
                    <p 
                    onClick={()=>handleOpen(video)}
                    className="ml-[10px] text-[15px] font-medium leading-[22px] text-blue-500 underline cursor-pointer">Delete</p>
                </div>
                </div>
            })
           }
           </div>
            <Pagination 
            currentPage={currentPage} 
            itemsPerPage={itemsPerPage} 
            totalItems={filteredData.length} 
            paginate={setCurrentPage} 
           />
        </> : <>
           <p className='text-center py-6'>No videos to display</p>
           </>}
      </Card>
      <AddWebDataModal
        title="Add Video"
        open={addWebDataModal}
        setOpen={setAddWebDataModal}
        clickedResponse={clickedResponse}
      />
    </>
  );
};

export default Videos;
