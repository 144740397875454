import {useEffect } from "react";



const Chat = () => {

  useEffect(() => {
    const script = document.createElement("script");
    script.src = 'https://chat.auralis.ai/chat_widget.js';
    script.async = true;
    script.setAttribute("widget_id", "d47e579f-fc6e-4432-8ff5-22f1361f7a06");
    document.body.appendChild(script);

    // Cleanup function
    return () => {
      const existingScript = document.querySelector("script[src='https://chat.auralis.ai/chat_widget.js']");
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []); 

  return (
    <div className="divSmallChat">
      
    </div>
  );
};
export default Chat;
