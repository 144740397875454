import { useTranslation } from "react-i18next";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const SourcesComponent = ({ globalLang, data }) => {
  const { i18n } = useTranslation();

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  return (
    <>
      {!data.isSkeleton? (
        <>
          {!data.mermaidCode &&
            !data.highchartCode &&
            data.preview &&
            data.preview.length > 0 && (
              <>
                <div className="flex items-end justify-end">
                  <div
                    className={`w-[80%] grid lg:grid-cols-2 grid-cols-1 gap-4 ${data.preview.length <= 2 ? "direction-rtl" : ""}`}
                  >
                    {data.preview.map((item, index) => {
                      return (
                        item.title &&
                        item.description &&
                        item.image && (
                          <a
                            key={index}
                            href={item.url}
                            rel="noreferrer"
                            target="_blank"
                            className={`flex flex-col ${data.preview.length <= 2 ? "mr-2 direction-ltr" : " direction-ltr"} mt-4`}
                          >
                            <div
                              className={`flex-grow bg-blue-800 flex flex-col items-end justify-end rounded-[12px] max-w-xl`}
                            >
                              {item.image && (
                                <img
                                  className="object-cover p-[12px] h-[190px] w-full rounded-[22px]"
                                  src={item.image}
                                  alt={item.title}
                                />
                              )}
                              <div style={globalLang ? { direction: "rtl" } : {}}
                                className={`flex-grow flex justify-center flex-col px-[12px] pb-[12px] w-full rounded-bl-[12px] bg-blue-800 text-white `}
                              >
                                <span className="font-bold text-[15px]">
                                  {item.title}
                                </span>
                                <span className="font-medium text-[#c4d6e5] text-[15px]">
                                  {truncateText(item.description, 120)}
                                </span>
                              </div>
                            </div>
                          </a>
                        )
                      );
                    })}
                  </div>
                  <img
                    className="w-[46px] h-[46px] rounded-[4px] ml-[12px] mr-[8px]"
                    src="/assets/images/touchpoint.png"
                    alt="touchpoint worker"
                  />
                </div>
                {
                  data.htmlUrls &&
                  data.htmlUrls.length > 0 &&
                  <div className="flex justify-end items-end mt-4">
                  <div className="flex flex-col justify-end items-end ">
                  {data.htmlUrls.map((source, index)=>{
                      return <div key={index} className={`flex items-center 
                      ${index === data.htmlUrls.length - 1 ? '' : 'mb-2'} bg-gray-200 
                      px-2 py-2 rounded-tl-[12px] rounded-tr-[12px] 
                      rounded-bl-[12px]`}>
                      <img src="/assets/icons/file.png" className="mr-[12px] w-[30px] h-[30px]"/>
                      <a 
                      target="_blank"
                      href={source}
                      rel="noreferrer"
                      className="text-blue-500 underline text-md underline">{source}</a>
                    </div>
                  })}
                  </div>
                  <img
                    className="ml-[12px] mr-[8px]"
                    src="/assets/images/touchpoint.png"
                    alt="chatbot"
                  />
                </div>
                }
              </>
            )}
          {!data.mermaidCode &&
            !data.highchartCode &&
            data.pdfUrls &&
            data.pdfUrls.length > 0 && (
              <>
                <div className="flex items-end justify-end">
                  <div style={globalLang ? { direction: "rtl" } : {}}
                    className={`w-[300px] grid lg:grid-cols-2 grid-cols-1 gap-4`}
                  >
                    {data.pdfUrls.map((item, index) => {
                      return (
                        <a
                          key={index}
                          href={item}
                          rel="noreferrer"
                          target="_blank"
                          className={`${data.pdfUrls.length <= 2 ? "mr-2" : "direction-ltr"
                            } mt-4`}
                        >
                          <div
                            className={`flex items-end justify-end flex-col rounded-lg max-w-xl`}
                          >
                            <img
                              className="object-cover w-[65px] rounded-lg"
                              src="/assets/images/pdf.png"
                              alt="pdf file"
                            />
                          </div>
                        </a>
                      );
                    })}
                  </div>
                  <img
                    className="w-[46px] h-[46px] rounded-[4px] ml-[12px] mr-[8px]"
                    src="/assets/images/touchpoint.png"
                    alt="touchpoint worker"
                  />
                </div>
              </>
            )}
        </>
      ) : (<>

        <div className="flex items-end justify-end">
          <div
            className={`w-[80%] grid lg:grid-cols-4 grid-cols-2 gap-4 ${(i18n.language === 'arabic' || i18n.language === 'hebrew') ? 'direction-ltr' : 'direction-rtl'}`}
          >
            {Array(data.skeletons).fill().map((_, index) => (
              <div
                key={index}
                className={`${data.skeletons <= 2 ? "mr-2" : "direction-ltr"} mt-4`}
              >
                <div
                  className={`skeleton flex items-end justify-end flex-col rounded-lg max-w-xl`}
                >
                  <Skeleton className="h-[150px]" />
                  <Skeleton className="w-full" count={5} />
                </div>
              </div>
            ))}
          </div>
          <img
            className="rounded-[4px] w-[46px] h-[46px] ml-[12px] mr-[8px]"
            src="/assets/images/touchpoint.png"
            alt="touchpoint worker"
          />
        </div>


      </>)}
    </>
  );
};

export default SourcesComponent;
