
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { languageContext } from "../../App";
import { Administration, CheckIn, Email, Finance, Involvement, People, Registration, Search, ToolBar } from "../Svgs/Svgs";

const LeftSection = ({}) => {

  const { t } = useTranslation();
  const { globalLang } = useContext(languageContext);

  return (
    <section className="pb-4 lg:w-1/5 lg:flex hidden flex-col transition ease-in-out delay-650">
      <div className="flex justify-between flex-col"
       style={{height: "calc(100vh - 160px)"}}>
       
        {/* <div className="leftSection mb-[20px]"  */}
        <div className="mb-[20px]" 
             style={globalLang? {direction:"rtl"} : {}}>
          <div className="flex items-center 2xl:mb-[20px] mb-3">
            <h3 className="text-gray-900 text-[18px] font-bold">
              {t("left.h1")}
            </h3>
          </div>
          <a 
           rel="noreferrer"
           target="_blank"
           href="https://docs.touchpointsoftware.com/People/index.html" 
           className="item mb-3 flex items-center py-[12px] px-4 bg-white rounded-[6px] text-gray-800">
            <People />
            <p className="cursor-pointer text-[16px] ml-[10px] leading-[22px] font-semibold">{t("left.p1")}</p>
          </a>
          <a
           rel="noreferrer"
           target="_blank"
           href="https://docs.touchpointsoftware.com/Organizations/index.html" 
           className="item mb-3 flex items-center py-[12px] px-4 bg-white rounded-[6px] text-gray-800">
            <Involvement />
            <p className="cursor-pointer text-[16px] ml-[10px] leading-[22px] 
            font-semibold hover:underline">{t("left.p2")}</p>
          </a>
          <a
           rel="noreferrer"
           target="_blank"
           href="https://docs.touchpointsoftware.com/SearchBuilder/index.html"  
           className="item mb-3 flex items-center py-[12px] px-4 bg-white rounded-[6px] text-gray-800">
            <Search />
            <p className="cursor-pointer text-[16px] ml-[10px] leading-[22px] font-semibold">{t("left.p3")}</p>
          </a>
          <a 
            rel="noreferrer"
            target="_blank"
            href="https://docs.touchpointsoftware.com/Checkin/index.html" 
            className="item mb-3 flex items-center py-[12px] px-4 bg-white rounded-[6px] text-gray-800">
            <CheckIn />
            <p className="cursor-pointer text-[16px] ml-[10px] leading-[22px] font-semibold hover:underline">{t("left.p4")}</p>
          </a>
          <a 
           rel="noreferrer"
           target="_blank"
           href="https://docs.touchpointsoftware.com/OnlineRegistration/index.html"
          className="item mb-3 flex items-center py-[12px] px-4 bg-white rounded-[6px] text-gray-800">
            <Registration />
            <p className="cursor-pointer text-[16px] ml-[10px] leading-[22px] font-semibold hover:underline">{t("left.p5")}</p>
          </a>
          <a 
            rel="noreferrer"
            target="_blank"
            href="https://docs.touchpointsoftware.com/Finance/index.html" 
            className="item mb-3 flex items-center py-[12px] px-4 bg-white rounded-[6px] text-gray-800">
            <Finance />
            <p className="cursor-pointer text-[16px] ml-[10px] leading-[22px] font-semibold hover:underline">{t("left.p6")}</p>
          </a>
          <a
           rel="noreferrer"
           target="_blank"
           href="https://docs.touchpointsoftware.com/EmailTexting/index.html"  
           className="item mb-3 flex items-center py-[12px] px-4 bg-white rounded-[6px] text-gray-800">
            <Email />
            <p className="cursor-pointer text-[16px] ml-[10px] leading-[22px] font-semibold hover:underline">{t("left.p7")}</p>
          </a>
          <a 
              rel="noreferrer"
               target="_blank"
               href="https://docs.touchpointsoftware.com/Administration/index.html" 
               className="item mb-3 flex items-center py-[12px] px-4 bg-white rounded-[6px] text-gray-800">
            <Administration />
            <p className="cursor-pointer text-[16px] ml-[10px] leading-[22px] font-semibold hover:underline">{t("left.p8")}</p>
          </a>
          <a 
            rel="noreferrer"
            target="_blank"
            href="https://docs.touchpointsoftware.com/BlueToolbar/index.html" 
            className="item mb-3 flex items-center py-[12px] px-4 bg-white rounded-[6px] text-gray-800">
            <ToolBar />
            <p className="cursor-pointer text-[16px] ml-[10px] leading-[22px] font-semibold hover:underline">{t("left.p9")}</p>
          </a>
        </div>
      </div>
    </section>
  );
};
export default LeftSection;
