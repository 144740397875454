import { Card, CardBody } from "@material-tailwind/react";
import Pagination from "../layouts/Pagination";
import AddWebDataModal from "../Modals/AddWebDataModal";

import { useState } from "react";
import moment from "moment";
import useList from "../../hooks/useList";
import { SearchIcon } from "../Svgs/Svgs";
import { ConfirmationModal } from "../Modals/ConfirmationModal";

const MyData = ({list, isUpdate, setIsUpdate}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen]=useState(false);
  const [search, setSearch] = useState("");
  const [clickedFile, setClickedFile]=useState(null);
  const itemsPerPage = 10;
  const data = useList("html", list, isUpdate);
  
  const filteredData = !search 
    ? data 
    : data.filter(item => item.title.toLowerCase().includes(search.toLowerCase()));

  
  const [addWebDataModal, setAddWebDataModal] = useState(false);
  const [clickedResponse, setClickedResponse] = useState(null);


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleOpen = (file) =>{
    setClickedFile(file);
    setOpen(!open)
  };
  
  const handleOpenModal = () => {
    setAddWebDataModal(!addWebDataModal);
    setClickedResponse({
      prompt: 'prompt',
      response: 'response',
      query_id: 'query_id',
      sources: ['sources'],
    });
  };

  return (
    <>
    <ConfirmationModal open={open} setOpen={setOpen} clickedFile={clickedFile} isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
      <Card className="bg-white p-[20px] rounded-[6px] border border-gray-500 ">
        <div className="flex justify-between items-center">
        <label className="relative">
        <input
          type="text"
          value={search}
          name="search"
          onChange={(e)=>setSearch(e.target.value)}
          placeholder="Search ..."
          className="border border-gray-500 mt-[10px] text-[15px]
          placeholder-gray-800 outline-0 text-gray-800 h-[42px] rounded-[8px] py-[9px] px-[12px] w-[518px]"
        />
        <SearchIcon />
      </label>
          <div>
            <button className="h-[40px] border-2 border-blue-500
             text-blue-500 font-semibold text-[14px] flex items-center
             rounded-[6px] px-4 py-[15px]" onClick={() => handleOpenModal()}>
              Add Web Data
            </button>
          </div>
        </div>
       {filteredData && filteredData.length > 0 ? <>
       
        <CardBody className="px-0  overflow-scroll md:overflow-hidden">
          <table className="w-full table-auto text-left">
            <thead>
              <tr>
                <th
                  className={`flex h-[40px] items-center justify-between uppercase bg-gray-500 text-[14px] text-black leading-[24px] p-4`}
                >
                  <p> file name </p>
                  <div className="flex pr-[6.5rem]">
                    <p className="mr-[25px]"> upload date </p>
                    <p className=""> Actions </p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
          { currentItems.map((file, index)=>{
            const isEven = index % 2 === 0;
            return  <tr key={file.doc_id}>
            <td className={`p-4 border-0 border-b border-gray-500 ${isEven ? '' : 'bg-[#f8f8f8]'}`}>
              <div
                className={`text-gray-800 text-[15px] font-medium border-0`}
              >
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center space-x-2">
                    <p className="cursor-pointer hover:underline ">
                      {file.title}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="w-[120px] text-[15px] text-gray-800 font-light">
                    {moment.unix(file.created_timestamp).format("MM/DD/YYYY")}
                    </p>
                    <div className="flex">
                    <a href={file.url} className="cursor-pointer text-[15px] leading-[22px] text-blue-500 underline font-medium hover:no-underline" download>
                      Download
                    </a>
                    <p onClick={()=>handleOpen(file)} className="ml-[10px] text-[15px] font-medium leading-[22px] text-blue-500 underline cursor-pointer hover:no-underline">Delete</p>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          }) }
            </tbody>
          </table>
        </CardBody>
        <Pagination 
         currentPage={currentPage} 
         itemsPerPage={itemsPerPage} 
         totalItems={filteredData.length} 
         paginate={setCurrentPage} 
        />
       </> : <>
       <div className="flex justify-center items-center">
            <p className="my-6">No data to display</p>
       </div>
       
       </>}
      </Card>
      <AddWebDataModal
        title="Add Web Data"
        open={addWebDataModal}
        setOpen={setAddWebDataModal}
        clickedResponse={clickedResponse}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
      />
    </>
  );
};

export default MyData;
