import { useTranslation } from "react-i18next";


const SupportBtn = ({}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="lg:flex hidden flex-col md:flex-row items-center justify-end">
                <a target="_blank" rel="noreferrer"
                href="https://docs.touchpointsoftware.com/index.html?_ga=2.112643716.1644764912.1687802220-1940633695.1687334516" 
                className="mr-[12px] border-2 border-white text-white rounded-[6px] py-[6px] px-4 border-2 
                hover:text-blue-500 hover:bg-white
                h-[42px] font-bold text-[15px] leading-[26px]"> {t('header.support')}</a>
                <a href="https://www.touchpointsoftware.com/demo"
                target="_blank" rel="noreferrer"
                className="border-2 border-white text-white rounded-[6px]  hover:text-blue-500 hover:bg-white
                py-[6px] px-4 border-2 font-bold text-[15px] leading-[26px]">{t('header.schedule')}</a>
            </div>
        </>
    );

}

export default SupportBtn