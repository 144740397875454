export const TEAM_ID = "touchpoint2";
export const ORG_ID = 1;
export const PUBLIC_URL = "/assets/images/";

export const countries = [
    { name: "English", code: "english", flag: "/assets/images/en.png" },
    // { name: "Hebrew", code: "hebrew", flag: "/assets/images/he.png" },
    { name: "Spanish", code: "spanish", flag: "/assets/images/sp.png" },
    { name: "Chinese (Simplified)", code: "zh-CN", flag: "/assets/images/ch.png" },
    { name: "Chinese (Traditional)", code: "zh-TW", flag: "/assets/images/ch.png" },
  
  ];

export const documents = [
 {id:6, title:'my_file_name.doc', description:'07/05/2021', url:'/assets/images/word.png'}, 
 {id:7, title:'my_file_name.xls', description:'07/05/2021', url:'/assets/images/excel.png'}, 
 {id:8, title:'my_file_name.pdf', description:'07/05/2021', url:'/assets/images/pdf-2.png'}, 
 {id:9, title:'my_file_name.doc', description:'07/05/2021', url:'/assets/images/word.png'}, 
 {id:10, title:'my_file_name.doc', description:'07/05/2021', url:'/assets/images/word.png'}, 
]


export const insights = [
  "Lots of developers are looking for help integrating the APIs with ReactJS",
  "The champions program is getting a lot of interest after the AWE last event",
  "The blog was the most loved resource with people averaging close to 12 mins on it per post and coming back multiple times",
  "There was a lack of content around customer onboarding and getting started for early stage companies",
  "Real time streaming services was another topic people looked for but could not find much information on"
]

export const options4 = {
    chart: {
      type: 'areaspline',
      height:300
    },
    title: {
      text: ''
    },
    credits:{
        enabled:false
    },
    series: [
      {
        name:"In minutes",
        lineWidth: 2,
        data: [1, 2, 1, 4, 3, 6,10, 11, 12,13,15,15, 4],
        marker: {
          radius:5,
          lineWidth: 2,  // border width
          lineColor: '#ffffff'  // border color
      },
      color:"#009df1",
      fillColor:"rgba(184, 216, 230, 0.3)"
      },
     
    ]
  };
  
export const options5 = {
    chart: {
      type:"column",
      height: 300,
    },
    title: {
      text: "",
    },
    yAxis: {
      title: {
        text: 'Values',
      },
    },
    credits:{
      enabled:false
  },
    series: [
      {
        name:"Support Agents",
        data: [1, 2, 8, 4, 3, 6, 10],
        color:"#009df1"
      },
      {
        name:"Customers",
        data: [2, 7, 12, 4, 6, 2, 15],
        color:"#99d8f9"
      },
    ],
  }

  export const options6 = {
    chart: {
      type: 'pie',
      height: 300
    },
    title: {
      text: ''
    },
    credits:{
        enabled: false
    },
    series: [
      {
        data: [
        { name: "Web Content", y: 1, color: "#ccebfc" },  
        { name: "Videos", y: 2, color: "#4cbaf5" },  
        { name: "Resources", y: 1, color: "#ccebfc" },  
        { name: "Documents", y: 4, color: "#99d8f9" },  
        { name: "Blog", y: 3, color: "#009df1" }
        ]
      }
    ]
  };


  export const tabs=[
    {
      label: "INSIGHTS",
      value: "insights",
    
    },
    {
      label: "QUERY EXPLORER",
      value: "query explorer",
      
    },
    {
      label: "EDITED RESPONSES",
      value: "edited responses",
    
    },
    {
      label: "MY DATA",
      value: "my data",
    
    },
  ];
 
