import axios from "axios";


export const flaggingResponse = async (queryId, flagged_comment)=>{
    const response = await axios.put(`${process.env.REACT_APP_MiDDLEWARE_URL}query/flag-query?org_id=${
        process.env.REACT_APP_ORG_ID }&widget_id=${process.env.REACT_APP_WIDGET_ID}&user_id${process.env.REACT_APP_USER_ID}&query_id=${queryId}`,
      {
        flagged_comment: flagged_comment,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      },
      )
      return response.data;
}

export const unflaggingResponse = async (queryId)=>{
    const response = await axios.put(
        `${process.env.REACT_APP_MiDDLEWARE_URL}query/unflag-query?org_id=${process.env.REACT_APP_ORG_ID
        }&widget_id=${process.env.REACT_APP_WIDGET_ID}&query_id=${queryId}&user_id=${process.env.REACT_APP_USER_ID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      );
      return response.data;
}
