import { useState } from "react";
import { Copy, Flag, Share } from "../Svgs/Svgs";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const DataChat = ({ data, globalLang, copiedMessageIndex, index, handleClickWithMessage }) => {
    const [showBox, setShowBox] = useState(false);


  return (
   <>
           {data.content &&
                  !data.errorEl &&
                  !data.highchartCode &&
                  !data.mermaidCode && (
                    <div className="relative copyItem flex items-end justify-end mt-4">
                   <div className="flex items-end justify-end" 
                      onMouseEnter={() => setShowBox(true)}
                      onMouseLeave={() => setShowBox(false)}>
                   <div style={globalLang ? { direction: "rtl" } : {}} className={`${showBox ? 'bg-opacity-90' : ''} 
                   rounded-tl-[12px] rounded-tr-[12px] 
                      rounded-bl-[12px] max-w-xl bg-blue-800`}>
                        <pre style={{fontFamily:"Montserrat", whiteSpace:"pre-wrap"}} 
                            className="text-[16px] font-normal text-white p-4">{data.content}</pre>
                        {/* <ReactMarkdown children={data.content.replace(/\n\s*\n/g, '\n')} remarkPlugins={[remarkGfm]}  
                        className="whitespace-pre-wrap text-[16px] font-normal text-white p-4" /> */}
                      </div>
                      {copiedMessageIndex === index && <div className="absolute top-[-30px] right-[160px]">
                        <span className="text-sm text-green-500">Copied!</span>
                      </div>}
                     {showBox && <div className="flex absolute bg-[#08345C] top-[-8px] right-[83px] rounded-[6px] 
                     px-[8px] py-[4px] border-2 border-blue-800">
                      <Copy handleClick={handleClickWithMessage} data={data} index={index} />
                      <Flag />
                      <Share />
                      </div>}
                   </div>
                      <img
                        className="w-[46px] h-[46px] rounded-[4px] ml-[12px] mr-[8px]"
                        src={`/assets/images/touchpoint.png`}
                        alt="nr"
                      />
                    </div>
                  )}
   </>
  );
};
export default DataChat;
