import React from "react";
import { SearchIcon } from "../Svgs/Svgs";

const SearchInput = ({search, setSearch}) => {
  return (
    <div className="flex justify-between items-center">
      <label className="relative">
        <input
          type="text"
          name="search"
          value={search}
          onChange={(e)=>setSearch(e.target.value)}
          placeholder="Search..."
          className="border border-gray-500 mt-[10px] text-[15px]
          placeholder-gray-800 outline-0 text-gray-800 h-[42px] rounded-[8px] py-[9px] px-[12px] w-[518px]"
        />
        <SearchIcon />
      </label>
      <div></div>
    </div>
  );
};

export default SearchInput;
