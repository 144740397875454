import { useEffect, useState } from "react"
import Front from "./Front";
import Chat from "./Chat";

const Steps = () => {
    const [step, setStep] = useState(2);
    const [showChat, setShowChat] = useState(false);
    const toggleChat = () => {
        setShowChat(!showChat);
    };
    
    switch (step) {
        case 1:
            return <Front step={step} setStep={setStep} toggleChat={toggleChat} showChat={showChat} setShowChat={setShowChat} />
        case 2:
            return <Chat step={step} setStep={setStep} toggleChat={toggleChat} showChat={showChat} setShowChat={setShowChat} />
        default:
            return null;
    }
}
export default Steps