import axios from "axios";


export const editResponse= async (input, output, query_id) => {
    const res = await axios
    .put(`${process.env.REACT_APP_MiDDLEWARE_URL}query/edit-query?org_id=${
       process.env.REACT_APP_ORG_ID
      }&widget_id=${process.env.REACT_APP_WIDGET_ID}&query_id=${query_id}&user_id=${
       process.env.REACT_APP_USER_ID
      }`,
      { input, output },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    )
    // const res= await axios.post(process.env.REACT_APP_BACKEND_URL+"edit-query", {
    //     org_id:ORG_ID,
    //     query_id,
    //     input:prompt, 
    //     output:edited_response,
    //     sources,
    //     conversation_id,
    //     team_id:TEAM_ID});
    return res.data;
}