import axios from "axios";


export const getInsights = async () => {
    const response = await axios.get(`${process.env.REACT_APP_MiDDLEWARE_URL}organisation/get-insights?org_id=${process.env.REACT_APP_ORG_ID}
    &widget_id=${process.env.REACT_APP_WIDGET_ID}&range=all&user_id=${process.env.REACT_APP_USER_ID}`,
    {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    return response.data;
}