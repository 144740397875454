import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { tabs } from '../config/constants';
import Cards from '../components/sections/Cards';
import DataAccordion from '../components/sections/dataAccordion';
import Insights from '../components/sections/Insights';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
    Spinner,
} from "@material-tailwind/react";
import MyData from '../components/sections/MyData';
import { getInsights } from '../api/insightsService';
import Videos from '../components/sections/Videos';
import Documents from '../components/sections/Documents';
import EditResponses from '../components/sections/EditResponses';
import { getList } from '../api/ListDocsService';
import { getQueries, rateQuery } from '../api/queriesService';

  
  let docsData = [];
  let coloredDocsData = [];
  let queriesData, savedData = [];

const InsightsPage = () => {
    const [insights, setInsights] = useState(null);
    const [isLoading, setIsloading] = useState(true);
    const [isUpdate, setIsUpdate] = useState(false);
    const [list, setList] = useState([]);
    const [queries, setQueries] = useState([]);
    const [options1, setOptions1] = useState({});
    const [options2, setOptions2] = useState({});
    const [options3, setOptions3] = useState({});
    const [options4, setOptions4] = useState({});

    const getAllQueries = async () => {
        const result = await getQueries();
        setQueries(result.data);
    };
    const getTouchPointList = async () => {
        const response = await getList();
        setList(response);
    }

    const rateResponse = async (id, rate) => {
        await rateQuery(id, rate);
    };
    useEffect(() => {
        getAllQueries();
        getTouchPointList();
    }, [isUpdate]);

    useEffect(() => {
        const getInsightsBackend = async () => {
            setIsloading(true);
            const result = await getInsights();
            const chartData= result.charts;
            if (result) {
                setInsights(result);
                const categories = Object.keys(chartData.labels);
                const label = Object.values(chartData.labels);
                const defaultColor = "rgba(52, 184, 87, 0.50)";
                const dates = Object.keys(chartData.queries_per_day);
                queriesData = Object.values(chartData.queries_per_day);
                const time = Object.keys(chartData.time_saved).map(Number);
                savedData = Object.values(chartData.time_saved);
                docsData =
                    chartData &&
                    Object.entries(chartData.docs).map(([name, y]) => ({
                        name,
                        y: y,
                    }));
                const customColors = ["#34B857"];
                coloredDocsData = docsData.map((item, index) => {
                    const color = customColors[index] ? customColors[index] : defaultColor;
                    return {
                        ...item,
                        color: color,
                    };
                });

                setOptions1({
                    chart: {
                        type: "areaspline",
                    },
                    title: {
                        text: "",
                    },
                    xAxis: {
                        categories: time,
                        title: {
                            text: "Time",
                        },
                    },
                    credits: {
                        enabled: false,
                    },
                    yAxis: {
                        title: {
                            text: "Time Saved",
                        },
                    },
                    series: [
                        {
                            events: {
                                legendItemClick: function () {
                                    return false;
                                },
                            },
                            states: {
                                hover: {
                                    enabled: true,
                                },
                                inactive: {
                                    opacity: 1,
                                },
                            },
                            enableMouseTracking: true,
                            name: "Time Saved",
                            data: savedData,
                            color: "#34B857",
                            fillColor: "rgba(52, 184, 87, 0.15)",
                        },
                    ],
                });

                setOptions2({
                    chart: {
                        type: "column",
                    },
                    title: {
                        text: "",
                    },
                    xAxis: {
                        type: "datetime",
                    },
                    yAxis: {
                        title: {
                            text: "Number of Queries",
                        },
                    },
                    credits: {
                        enabled: false,
                    },
                    series: [
                        {
                            states: {
                                hover: {
                                    enabled: true,
                                },
                                inactive: {
                                    opacity: 1,
                                },
                            },
                            events: {
                                legendItemClick: function () {
                                    return false;
                                },
                            },
                            enableMouseTracking: true,
                            name: "Queries Data",
                            data: queriesData,
                            pointStart: Date.parse(dates[0]),
                            pointInterval: 24 * 3600 * 1000,
                            color: "#34B857",
                            zones: [
                                {
                                    value: 10,
                                    color: "#FF0000",
                                },
                                {
                                    value: Infinity,
                                    color: "#34B857",
                                },
                            ],
                        },
                    ],
                });
                setOptions3({
                    chart: {
                        type: "pie",
                    },
                    title: {
                        text: "",
                    },
                    tooltip: {
                        pointFormat: "{series.name}: <b>{point.y}</b>",
                        enabled: false,
                    },
                    accessibility: {
                        point: {
                            valueSuffix: "%",
                        },
                    },
                    credits: {
                        enabled: false,
                    },
                    plotOptions: {
                        pie: {
                            showInLegend: true,
                            allowPointSelect: false,
                            cursor: "default",
                            stickyTracking: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                                inactive: {
                                    opacity: 1,
                                },
                            },
                            dataLabels: {
                                enabled: true,
                                format: "<b>{point.name}</b>: {point.y}",
                            },
                        },
                        series: {
                            point: {
                                events: {
                                    legendItemClick: function () {
                                        return false; // <== returning false will cancel the default action
                                    },
                                },
                            },
                        },
                    },

                    series: [
                        {
                            name: "Docs",
                            colorByPoint: true,
                            data: coloredDocsData,
                        },
                    ],
                });

                setOptions4({
                    colors: ["#128BFE"],
                    tooltip: {
                        enabled: false,
                    },
                    series: [
                        {
                            dataLabels: {
                                enabled: true,
                                color: "#0B101C",
                                fontSize: "14px",
                                fontWeight: 600,
                                fontFamily: "Poppins",
                            },
                            states: {
                                hover: {
                                    enabled: false,
                                },
                                inactive: {
                                    opacity: 1,
                                },
                            },
                            events: {
                                legendItemClick: function () {
                                    return false;
                                },
                            },
                            data: label,
                        },
                    ],
                    chart: {
                        type: "bar",
                        height: 400,
                    },
                    title: {
                        text: "",
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            colorByPoint: true,
                            pointWidth: 22,
                        },
                    },
                    credits: {
                        enabled: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    xAxis: {
                        categories: categories,
                        lineWidth: 0,
                        labels: {
                            enabled: true,
                            style: {
                                color: "#0B101C",
                                fontSize: "14px",
                                fontWeight: 700,
                                fontFamily: "Poppins",
                            },
                        },
                        tickLength: 0,
                    },
                    yAxis: {
                        lineWidth: 0,
                        labels: {
                            enabled: false,
                        },
                        title: {
                            text: null,
                        },
                    },
                    legend: {
                        enabled: false,
                    },
                });
            } else {
                setOptions1({});
                setOptions2({});
                setOptions3({});
                setOptions4({});
            }
            setIsloading(false);
        }
        getInsightsBackend()
    }, [])


    if (isLoading) {
        return <div className='h-screen flex items-center justify-center bg-gray-100'>
            <Spinner className='w-24 h-24' />
        </div>
    }


    return (
        <>
            <section className='font-montserrat xl:mx-[240px] my-[40px] lg:mx-[140px] mx-[20px]'>
                <Tabs value="insights">
                    <TabsHeader
                        className='bg-gray-500 rounded-[6px] p-[6px]'
                        indicatorProps={{
                            className: "bg-blue-500 text-white",
                        }}
                    >
                        {tabs.map(({ label, value }) => (
                            <Tab
                                className={`font-bold text-[16px]`} key={value} value={value}>
                                {label}
                            </Tab>
                        ))}
                    </TabsHeader>
                    <TabsBody className='mt-6'>
                        <TabPanel key="insights" value="insights" className='p-0'>
                            <div className='mb-[30px]'>
                                <h1 className="font-bold text-[32px] leading-[30px] text-black">Insights</h1>
                            </div>
                            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[20px] mb-[20px]">
                                <Cards title="Number of users" numberEl={insights && insights.insights.number_of_users.value} />
                                <Cards title="Number of questions" numberEl={insights && insights.insights.number_of_questions.value} />
                                <Cards title="Responses rated 4* or more"
                                    numberEl={`${insights && insights.insights.responses_rated_more_than_4.value}%`} />
                                <Cards title="Avg time saved per user" numberEl={`${insights && insights.insights.average_time_saved.value} min`} />
                            </div>
                            <div className="grid lg:grid-cols-2 gap-[20px] mb-[20px]">
                                <div className="bg-white dark:bg-transparent p-[20px] rounded-[6px] border border-gray-500">
                                    <div className="flex justify-between mb-8">
                                        <h3 className='text-[14px] text-gray-900 uppercase font-bold leading-[18px]'>Time saved per user</h3>
                                    </div>
                                    {options1 && <HighchartsReact highcharts={Highcharts} options={options1} />}
                                </div>
                                <div className="bg-white dark:bg-transparent p-[20px] rounded-[6px] border border-gray-500">
                                    <div className="flex justify-between mb-8">
                                        <h3 className='text-[14px] text-gray-900 uppercase font-bold leading-[18px]'>Queries per day</h3>
                                    </div>
                                    {options2 && <HighchartsReact highcharts={Highcharts} options={options2} />}
                                </div>
                                <Insights />
                                <div className="bg-white p-[20px] rounded-[6px] border border-gray-500">
                                    <div className="flex justify-between mb-4">
                                        <h3 className='text-[14px] text-gray-900 uppercase font-bold leading-[18px]'>Data sources</h3>
                                    </div>
                                    <div className="flex justify-center ">
                                        {options3 && <HighchartsReact highcharts={Highcharts} options={options3} />}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel key="query explorer" value="query explorer" className='p-0'>
                            <div className='mb-[30px]'>
                                <h1 className="font-semibold text-[30px] leading-[41px] text-black">Query Explorer</h1>
                            </div>
                            <DataAccordion rateResponse={rateResponse} queries={queries} getAllQueries={getAllQueries} />
                        </TabPanel>
                        <TabPanel key="edited responses" value="edited responses" className='p-0'>
                            <div className='mb-[30px]'>
                                <h1 className="font-semibold text-[30px] leading-[41px] text-black">Edited Responses</h1>
                            </div>
                            <EditResponses queries={queries} getAllQueries={getAllQueries} />
                        </TabPanel>
                        <TabPanel key="my data" value="my data" className='p-0'>
                            <div className='mb-[30px]'>
                                <h1 className="font-semibold text-[30px] leading-[41px] text-black">My Data</h1>
                            </div>
                            <MyData list={list} getTouchPointList={getTouchPointList} isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
                            <Videos list={list} isUpdate={isUpdate} />
                            <Documents list={list} getTouchPointList={getTouchPointList} />
                        </TabPanel>
                    </TabsBody>
                </Tabs>

            </section>
        </>
    )
}

export default InsightsPage