import { useContext, useState } from "react";
import RightElements from "../layouts/RightElements";
import { useTranslation } from "react-i18next";
import { languageContext } from "../../App";


const RightSection = ({handleSubmit, setFormData, formData }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState('');
  const { globalLang} = useContext(languageContext);

  const handleClick = async (data, e) => {
    setFormData({ ...formData, input: data });
    setSelected(data);
    handleSubmit(data, e)
  }

  return (
    <section className="lg:w-1/5 lg:flex hidden flex-col justify-between" style={{ height: "calc(100vh - 160px)"}}>
      <div className="flex flex-col h-full justify-between" style={globalLang? {direction:"rtl"} : {}}> 
        <div>
        <div className="2xl:mb-[20px]">
          <h3 className="flex items-center text-gray-800 text-[18px] font-bold flex">
            <span className={` ${globalLang ? 'ml-[12px]' : 'mr-[12px]'}`}>
              <img src="/assets/icons/brain.svg" alt="brain icon" />
            </span>
            {t("right.h1")}
          </h3>
        </div>
        <RightElements handleClick={handleClick} selected={selected} text={t("right.h2")} />
        <RightElements handleClick={handleClick} selected={selected} text={t("right.h3")} />
        <RightElements handleClick={handleClick} selected={selected} text={t("right.h4")} />
        <RightElements handleClick={handleClick} selected={selected} text={t("right.h5")} />
        <RightElements handleClick={handleClick} selected={selected} text={t("right.h6")} />
        <RightElements handleClick={handleClick} selected={selected} text={t("right.h7")} />
        </div>
        <div className="flex justify-end">
          <img src="/assets/images/zuro.png" alt="zuro logo" className="" />
        </div>
      </div>
    </section>
  )
}
export default RightSection
