import axios from "axios";

export const addData = async (url, title) => {
    const response = await axios.post(`${process.env.REACT_APP_MiDDLEWARE_URL}docs/upload-docs?&user_id${
        process.env.REACT_APP_USER_ID
      }`, {
        doc_type: url.endsWith(".xml") ? "sitemap" : "web",
        widget_id: process.env.REACT_APP_WIDGET_ID,
        org_id: process.env.REACT_APP_ORG_ID,
        library_id: 1,
        title,
        url,
      }, {
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
      })
    return response.data;
}

export const getList = async () => {
    const response = await axios.get(`${
       process.env.REACT_APP_MiDDLEWARE_URL
      }organisation/get-docs?org_id=${
        process.env.REACT_APP_ORG_ID
      }&widget_id=${process.env.REACT_APP_WIDGET_ID}&range=all&search=&page=&user_id=${
        process.env.REACT_APP_USER_ID
      }`,
    {  headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },});
    return response.data.data;
}

export const deleteDocument = async (doc_id) => {
    const response = await axios.delete(
        `${process.env.REACT_APP_MiDDLEWARE_URL}docs/delete-docs?org_id=${process.env.REACT_APP_ORG_ID}&widget_id=${process.env.REACT_APP_WIDGET_ID}&user_id=${process.env.REACT_APP_USER_ID}`,
        {
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
            data: {
                docs: [doc_id]
            }
        }
    );
    return response.data;
}