import React from "react";
import { Card, CardBody, CardHeader } from "@material-tailwind/react";
import useList from "../../hooks/useList";
import { PUBLIC_URL } from "../../config/constants";
import { SearchIcon } from "../Svgs/Svgs";
import { useState } from "react";
import AddWebDataModal from "../Modals/AddWebDataModal";

const Documents = ({ list }) => {
  const documents = useList("pdf", list);
  const [search, setSearch] = useState("");
  const [addWebDataModal, setAddWebDataModal] = useState(false);
  const [clickedResponse, setClickedResponse] = useState(null);

  const filteredData = !search
    ? documents
    : documents.filter((item) =>
        item.title.toLowerCase().includes(search.toLowerCase())
      );

  const getDocIcon = (url) => {
    if (url.endsWith(".pdf")) return `${PUBLIC_URL}pdf-2.png`;
    if (url.endsWith(".doc") || url.endsWith(".docx"))
      return `${PUBLIC_URL}word.png`;
    if (url.endsWith(".xls") || url.endsWith(".xlsx"))
      return `${PUBLIC_URL}excel.png`;
    return "/default/icon.png";
  };

  const handleOpenModal = () => {
    setAddWebDataModal(!addWebDataModal);
    setClickedResponse({
      prompt: "prompt",
      response: "response",
      query_id: "query_id",
      sources: ["sources"],
    });
  };

  return (
    <>
      <Card className="bg-white p-[20px] rounded-[6px] border border-gray-500 ">
        <div className="mb-[20px] mt-[10px]">
          <h2 className="font-bold text-[14px] leading-[18px] uppercase text-black">
            Documents
          </h2>
        </div>
        <div className="flex justify-between items-center">
          <label className="relative">
            <input
              type="text"
              value={search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
              className="border border-gray-500 mt-[10px] text-[15px]
          placeholder-gray-800 outline-0 text-gray-800 h-[42px] rounded-[8px] py-[9px] px-[12px] w-[518px]"
            />
            <SearchIcon />
          </label>
          <div>
            <button
            onClick={() => handleOpenModal()}
             className="h-[40px] flex items-center border-2 border-blue-500 text-blue-500 font-semibold text-[14px] rounded-[6px] px-4 py-[15px]">
              Add Document
            </button>
          </div>
        </div>
        <CardBody className="px-0  overflow-scroll md:overflow-hidden">
          {filteredData && filteredData.length > 0 ? (
            <div className="grid xl:grid-cols-5 lg:grid-cols-2 grid-cols-2 gap-4 mt-[20px]">
              {filteredData.map((doc) => {
                return (
                  <div
                    key={doc.title}
                    className="bg-[#F8F8F8] flex flex-col justify-between overflow-hidden"
                  >
                    <CardHeader
                      floated={false}
                      shadow={false}
                      color="transparent"
                      className="m-0 rounded-none"
                    >
                      <img
                        className="px-4 pt-4 pb-[20px] w-full object-cover"
                        src={doc.url && getDocIcon(doc.url)}
                        alt={doc.title}
                      />
                    </CardHeader>
                    <div className="cursor-pointer px-4">
                      <p className="text-[15px] font-medium leading-[18px] text-gray-800">
                        {doc.title}
                      </p>
                      <p className="text-[15px] font-medium text-gray-800 my-[8px]">
                        {doc.description}
                      </p>
                    </div>
                    <div className="flex px-4 pb-4">
                      <p className="text-[15px] font-medium leading-[22px] text-blue-500 underline cursor-pointer">
                        Download
                      </p>
                      <p className="ml-[10px] text-[15px] font-medium leading-[22px] text-blue-500 underline cursor-pointer">
                        Delete
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <p className="text-center">No documents to display</p>
            </>
          )}
        </CardBody>
      </Card>
      <AddWebDataModal
        title="Add Document"
        open={addWebDataModal}
        setOpen={setAddWebDataModal}
        clickedResponse={clickedResponse}
      />
    </>
  );
};

export default Documents;
