import axios from "axios";
import { TEAM_ID, ORG_ID } from "../config/constants";


export const getQueries = async () => {

    const response =  await axios
    .get(
      `${process.env.REACT_APP_MiDDLEWARE_URL
      }organisation/get-queries?org_id=${
        process.env.REACT_APP_ORG_ID
      }&widget_id=${process.env.REACT_APP_WIDGET_ID}&search=&page=&user_id=${
        process.env.REACT_APP_USER_ID
      }&range=all`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    )
    return response.data;
}

export const getConversation = async (conversation_id) => {
    const res = await axios.get(
        `${ process.env.REACT_APP_MiDDLEWARE_URL
        }conversation/download-conversations?org_id=${process.env.REACT_APP_ORG_ID
        }&widget_id=${process.env.REACT_APP_WIDGET_ID}&conversation_id=${conversation_id}&user_id=${
            process.env.REACT_APP_USER_ID
        }`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      );
    return res.data;
}

export const rateQuery = async (queryId, rating) => {
    try {
        const res = await axios.put(
          `${process.env.REACT_APP_MiDDLEWARE_URL}query/set-rating?org_id=${
            process.env.REACT_APP_ORG_ID
          }&widget_id=${process.env.REACT_APP_WIDGET_ID}&query_id=${queryId}&user_id=${process.env.REACT_APP_USER_ID}`,
          { rating: rating },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
          }
        );
        if (res) {
          return res.data
        }
      } catch (error) {
        console.log(error);
      }


    // const response = await axios.post(process.env.REACT_APP_BACKEND_URL + 
    //     `rate-query`, {
    //         query_id, rating
    //     });
    // return response.data;
}

export const rateConversation = async (conversation_id, rating) => {
    const response = await axios.post(process.env.REACT_APP_BACKEND_URL + 
        `rate-conversation`, {conversation_id, rating, team_id:TEAM_ID});
    return response.data;
}